import { createApp } from 'vue'

import ReadMore from 'vue-read-more'
import Maska from 'maska'

import App from './App.vue'
import router from './router.js'
import store from './store/index.js'

import BaseBadge from './components/ui/BaseBadge.vue'
import BaseDialog from './components/ui/BaseDialog.vue'
import BaseSpinner from './components/ui/BaseSpinner.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})

const app = createApp(App)

app.use(vuetify)
app.use(router)
app.use(store)
app.use(ReadMore)
app.use(Maska)

app.component('base-dialog', BaseDialog)
app.component('base-badge', BaseBadge)
app.component('base-spinner', BaseSpinner)

app.mount('#app')
