<template>
  <div v-if="!pedidofinalizado && !carregamentoPedidos" class="fullbody">
    <div class="resumo-compra">
      <h1>Review Your Order</h1>
      <hr />
      <div>
        <ul v-for="resumoCompra in resumoCompras" :key="resumoCompra.codigo">
          <div class="container">
            <div id="resumoItem" class="row">
              <div class="col-md">
                <strong>{{ resumoCompra.nomeproduto }}</strong>
                <hr />
                <div class="row">
                  <div class="col"><strong>Description:</strong></div>
                  <div class="col"><strong>Quantity:</strong></div>
                  <div class="col"><strong>Price per unit:</strong></div>
                  <div class="col"><strong>Total:</strong></div>
                </div>
                <ul
                  v-for="variacao in resumoCompra.variacao"
                  :key="variacao.descricao_sku"
                  style="list-style: disc"
                >
                  <li>
                    <div class="row">
                      <div class="col">{{ variacao.descricao_sku }}</div>
                      <div class="col">
                        {{ variacao.quantidade }}
                      </div>
                      <div class="col">
                        ${{
                          variacao.preco.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })
                        }}
                        USD
                      </div>
                      <div class="col">
                        ${{
                          (variacao.quantidade * variacao.preco).toLocaleString(
                            "pt-br",
                            {
                              minimumFractionDigits: 2,
                            }
                          )
                        }}
                        USD
                      </div>
                    </div>
                  </li>
                </ul>
                <br />
                <li><strong>Items:</strong> {{ resumoCompra.qtd }}</li>
              </div>
              <div class="col-md-5" v-if="resumoCompra.variacao[0].url">
                <img
                  :src="resumoCompra.variacao[0].url"
                  style="width: 8rem"
                  alt=""
                />
              </div>
              <div class="col-md-5" v-else>
                <img :src="resumoCompra.url" style="width: 8rem" alt="" />
              </div>
            </div>
          </div>
        </ul>
        <div class="container">
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="Faça uma observação aqui"
              id="floatingTextarea2"
              v-model.trim="observacao.val"
              style="height: 100px"
            ></textarea>
            <label id="observation" for="floatingTextarea2">Comments:</label>
          </div>
          <div class="centralizador">
            <strong>Total Order: </strong> ${{ carrinhoTotal }} USD
            <p v-if="!cartIsValid">
              Please, select a product before confirm the order.
            </p>
          </div>
        </div>
      </div>
      <br />
      <div class="enderecoEntrega" v-if="dadosCliente.endereco != null">
        <h1>
          Delivery Address
          <button @click="editarEndereco()" class="styleBtn">
            Change Address
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-pencil"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
              />
            </svg>
          </button>
        </h1>

        <hr />
        <div class="enderecoStyle">
          <p>
            {{ dadosCliente.endereco[0].cidade }} -
            {{ dadosCliente.endereco[0].uf }}
          </p>
          <p>
            {{ dadosCliente.endereco[0].logradouro }}, Nº
            {{ dadosCliente.endereco[0].numero }},
            {{ dadosCliente.endereco[0].bairro }}
          </p>
          <p>CEP: {{ dadosCliente.endereco[0].cep }}</p>
        </div>
      </div>

      <div class="button-control">
        <br />
        <button @click="validateForm">Confirm</button>
      </div>
    </div>
  </div>
  <div v-if="carregamentoPedidos" class="fullbody">
    <base-spinner></base-spinner><br />
  </div>

  <div v-if="pedidofinalizado && !carregamentoPedidos" class="fullbody">
    <div class="centralizador">
      <div class="container">
        <div
          v-if="
            statusPedido.status == 'Pedido cadastrado com sucesso.' ||
            'Venda balcão cadastrado com sucesso.'
          "
        >
          <h1 class="mt-3">Thank you!</h1>
          <h2>Order successfully registered.</h2>
        </div>
        <h1
          class="mt-3"
          v-if="
            statusPedido.status != 'Pedido cadastrado com sucesso.' &&
            statusPedido.status != 'Venda balcão cadastrado com sucesso.'
          "
        >
          Something went wrong!
        </h1>
        <hr />
        <h4>Order Code: {{ statusPedido.codigo_pedido }}</h4>
        <div class="centrali">
          <router-link :to="meusPedidosLink" v-slot="{ href, route, navigate }">
            <button :href="href" @click="navigate">
              Go to my orders {{ route.MeusPedidos }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <br />
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      cartIsValid: true,
      observacao: {
        val: "",
        isValid: true,
      },
      pedidofinalizado: false,
    };
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    top() {
      window.scrollTo(0, 0);
    },
    async carregarMeusPedidos() {
      await this.$store.dispatch("login/carregarMeusPedidos", {
        cnpj: this.empresarodape.cnpj,
        senha: this.dadosCliente.senha,
        email: this.dadosCliente.email,
        dataInicio: moment().startOf("month").format("YYYY-MM-DD"),
        dataFim: moment().format("YYYY-MM-DD"),
      });
    },
    async validateForm() {
      this.top();
      if (this.$store.getters["carrinhos/somaTotal"] <= 0) {
        this.cartIsValid = false;
      } else {
        await this.$store.dispatch("requests/realizarPedido", {
          codigoempresa: this.empresarodape.cnpj,
          observacao: this.observacao.val,
        });

        this.carregarMeusPedidos();
        this.$store.dispatch("produtos/resetSelect");
        this.$store.dispatch("carrinhos/resetSelect");
        this.pedidofinalizado = true;
      }
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    editarEndereco() {
      this.$router.push(this.cadastroLink);
    },
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("requests", ["carregamentoPedidos"]),

    statusPedido() {
      return this.$store.getters["requests/statusPedido"];
    },
    cadastroLink() {
      return "/register";
    },
    resumoCompras() {
      return this.$store.getters["carrinhos/produtos"];
    },
    statusCompra() {
      return this.$store.getters["requests/statusCompra"];
    },
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    meusPedidosLink() {
      return "/products/myorders";
    },
    carrinhoTotal() {
      return this.$store.getters["carrinhos/somaTotal"].toLocaleString(
        "pt-br",
        { minimumFractionDigits: 2 }
      );
    },
    itemTotal() {
      return (this.resumoCompra.preco * this.item.qtd).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
  },
  created() {
    this.carregarRodape();
    this.carregarCategorias();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

h1 {
  color: var(--primary-color);
  margin: 0px 40px;
}

h4 {
  text-align: center;
  font-weight: lighter;
}

.resumo-compra {
  text-align: left;
  font-size: 25px;
  margin: 30px auto;
  max-width: 80rem;
}

h3 {
  font-size: calc(1rem + 0.9vw);
  font-weight: 500;
  line-height: 1.2;
  margin: 40px 0px;
  color: #292929;
  text-align: left;
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
}

.fullbody {
  min-height: 100vh;
}

#resumoItem {
  border: 1px solid rgba(0, 0, 0, 0.322);
  border-radius: 20px;
  max-width: 55rem;
  text-align: center;
  margin: auto;
  align-items: center;
  font-size: 15px;
  padding: 20px;
}

#observation {
  font-weight: 500;
  margin: 0px 10px;
  padding: 1rem 0.75rem;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.form-floating {
  border-radius: 20px;
  max-width: 40rem;
  margin: 20px auto;
  font-size: 15px;
}

.centralizador {
  text-align: center;
}

.enderecoStyle {
  margin-left: 30px;
}

ul {
  list-style: none;
  text-align: left;
}

li {
  text-align: left;
}

.button-control {
  margin: auto;
  max-width: 50rem;
  text-align: center;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: 10px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

.styleBtn {
  font-weight: normal;
  font-size: 25px;
}

.edit {
  background-color: red;
  border: 1px solid red;
}

.edit:hover {
  background-color: red;
  border: 1px solid red;
}

button:hover,
button:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>
