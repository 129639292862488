export default {
  async cadastrarCliente(context, payload) {
    const cnpjEmpresa = payload.empresacnpj;

    const newCadastro = {
      codigo: 0,
      nome: payload.nome,
      razao: payload.razao,
      cnpj: payload.cnpj,
      rg: "",
      cpf: payload.cpf,
      senha: payload.senha,
      telefone: payload.telefone,
      celular: payload.celular,
      contato: payload.contato,
      IE: payload.ie,
      email: payload.email,
      site: "",
      observacao: "",
      foto: "",
      codigo_mobile: 1,
      endereco: [
        {
          codigo: 0,
          cidade: payload.cidade,
          logradouro: payload.logradouro,
          bairro: "",
          referencia: "",
          numero: payload.numero,
          complemento: payload.complemento,
          cep: payload.cep,
          uf: payload.uf,
        },
      ],
    };

    console.log(newCadastro);

    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Cliente/Post?token=sensualle&cnpj=${cnpjEmpresa}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newCadastro),
      }
    );

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Erro ao enviar mensagem."
      );
      throw error;
    }

    const responseData = await response.json();

    const codigoCliente = responseData.Codigo_cliente;
    const status = responseData.Status;
    const codigoEntrega = responseData.codigo_endereco;

    context.commit("addCadastro", codigoCliente);
    context.commit("addStatus", status);
    context.commit("addEntrega", codigoEntrega);
  },

  async buscarCep(context, payload) {
    const cep = payload;

    if (cep.length !== 9) {
      window.alert("CEP Inválido!");
      return;
    }

    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    const responseData = await response.json();

    if (cep.length == 9 && responseData["erro"] === "true") {
      window.alert("CEP Inválido!");
      return;
    }

    context.commit("getCep", responseData);
  },
};
