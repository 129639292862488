<template>
  <div v-if="isCarregado == false" class="fullbody">
    <base-spinner></base-spinner>
  </div>
  <div
    v-if="this.categoriaDada != null && isCarregado == true"
    class="fullbody"
  >
    <div class="d-flex container justify-content-space-between">
      <h1>{{ categoriaDada[0].categoria }}</h1>
      <div id="margin" class="row">
        <p id="ordem" class="col">Order by:</p>
        <select
          @change="onChange($event)"
          class="form-select d-inline col"
          style="width: fit-content"
          aria-label="select"
          v-model="ordenacao"
          id="orderby"
        >
          <option value="LAN%C3%87AMENTO" selected>New Arrivals</option>
          <option value="MENOR%20PRE%C3%87O">Lower Price</option>
          <option value="MAIOR%20PRE%C3%87O">Highest Price</option>
          <option value="MAIS%20VENDIDOS">Bestselling Products</option>
          <option value="MAIOR%20ESTOQUE">Most Stocked</option>
          <option value="MENOR%20ESTOQUE">Least Stocked</option>
        </select>
      </div>
    </div>
    <hr />
    <img
      v-if="topovisibility"
      @click="top"
      id="back-to-top"
      src="../../public/totop.png"
      alt="voltar ao topo botão"
    />
    <div>
      <div class="container"></div>
      <div class="container">
        <ul style="padding: 0">
          <div class="row" v-if="!isCargando">
            <item-produto
              class="col-md col-6 p-1"
              v-for="produto in categoriaDada"
              :key="produto.nomeproduto"
              :nomeproduto="produto.nomeproduto"
              :url="produto.url"
              :detalhes="produto.detalhes"
              :preco="produto.preco"
              :saldo="produto.saldo"
              :codigo="produto.codigo"
              :referencia="produto.referencia"
            >
            </item-produto>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div v-if="this.categoriaDada == null && isCarregado == true" class="acabou">
    <h1>Ops...</h1>
    <hr />
    <p>Não temos produtos disponíveis desta categoria no momento.</p>
  </div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import ItemProduto from "../components/produtos/ItemProduto.vue";
import { mapState } from "vuex";
import router from "@/router";

export default {
  components: {
    ItemProduto,
  },
  data() {
    return {
      ordenacao: "LAN%C3%87AMENTO",
      isCargando: false,
      topovisibility: false,
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("produtos", ["categoriaDada"]),
    ...mapState("produtos", ["isCarregado"]),

    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    categorianumber() {
      return this.$store.getters["loja/categoriaSelecionada"];
    },
  },
  methods: {
    async carregarCategoriaSelecionada() {
      if (this.categoriaDada.length === 0 || this.categoriaDada.length === 4) {
        this.isCargando = true;
        await this.$store.dispatch("produtos/carregarCategoriaSelecionada", {
          ordem: this.ordenacao,
        });
        this.isCargando = false;
      }
    },
    async onChange(event) {
      this.ordenacao = event.target.value;
      this.$store.dispatch("produtos/isProdutoLoading");

      await this.$store.dispatch("loja/resetPaginacao");
      await this.$store.dispatch("produtos/resetProdutos");
      await this.$store.dispatch("produtos/carregarCategoriaSelecionada", {
        ordem: this.ordenacao,
      });

      this.$nextTick(() => {
        this.$store.dispatch("produtos/isProdutoReady");
      });
    },
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.carregarRodape();
    this.carregarCategoriaSelecionada();
    this.carregarCategorias();
  },
  watch: {
    async categorianumber(newCategory, oldCategory) {
      if (newCategory != oldCategory) {
        this.$store.dispatch("produtos/isProdutoLoading");

        await this.$store.dispatch("loja/resetPaginacao");
        await this.$store.dispatch("produtos/resetProdutos");
        await this.$store.dispatch("produtos/carregarCategoriaSelecionada", {
          ordem: this.ordenacao,
        });

        this.$store.dispatch("produtos/isProdutoReady");
      }
    },
  },
  mounted() {
    if (
      router.currentRoute.value.fullPath ==
      "/" + "products/categories/" + this.categoria
    ) {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 1145) {
          this.topovisibility = true;
        } else {
          this.topovisibility = false;
        }
      });
    }
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
#orderby:focus {
  border-color: var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #13433a;
}

h1 {
  color: var(--primary-color);
  margin: 40px;
}

#back-to-top {
  position: fixed;
  width: 80px;
  bottom: 0%;
  right: 0%;
  cursor: pointer;
}

.fullbody {
  min-height: 100vh;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

ul {
  list-style: none;
  margin: 2rem auto;
}

div {
  margin: 0;
  padding: 0;
  margin: auto;
  max-width: 85rem;
}

.acabou {
  min-height: 100vh;
}
.acabou > p {
  margin-bottom: 100px;
  text-align: center;
  font-size: 20px;
}

div.search {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin: 2rem auto;
  width: 60%;
  text-align: center;
}

div.search > img {
  width: 5%;
}

img {
  margin-left: 1rem;
}

input {
  margin: 0;
  margin: auto;
  width: 90%;
}
</style>
