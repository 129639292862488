export default {
    carregarRodape(state, payload) {
        state.empresarodape = payload;
    },
    setarVendedores(state, payload) {
        state.vendedores = payload;
    },
    setarGlobal(state, payload) {
        state.global = payload;
    },
    setarPermissaoUsuario(state, payload) {
        state.permissaoUsuario = payload;
    }
}