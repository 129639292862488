export default {
    dadosCliente(state) {
        return state.dadosCliente;
    },
    dadosPedidos(state) {
        return state.dadosPedidos;
    },
    usuarioLogado(state) {
        return state.usuarioLogado;
    },
    carregamentoPedidos(state) {
        return state.carregamentoPedidos;
    }

}