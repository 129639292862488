<template>
  <li id="carrinho">
    <div class="row justify-content-center">
      <router-link :to="produtoPageLink" v-slot="{ href, route, navigate }">
        <button id="imgbutton" :href="href" @click="navigate">
          <img
            v-if="item.variacao[0].url"
            class="col-lg-4 col-sm-6"
            :src="item.variacao[0].url"
            :alt="item.nomeproduto"
          />
          <img
            v-else
            class="col-lg-4 col-sm-6"
            :src="item.url"
            :alt="item.nomeproduto"
          />
          {{ route.ProdutoPage }}
        </button>
      </router-link>
      <div class="col-md" style="padding: 0rem">
        <div class="row">
          <button @click="remove">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-trash3 text-danger"
              viewBox="0 0 16 16"
            >
              <path
                d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
              />
            </svg>
          </button>
        </div>
        <div class="col-8" style="margin-top: -2rem">
          <h3 style="font-size: 20px">{{ item.nomeproduto }}</h3>
        </div>
        <div id="referencias">
          <hr />
          <ul class="row">
            <sku-produtos
              v-for="variacoes in item.variacao"
              :key="variacoes.codigo_sku"
              :variacoes="variacoes"
            ></sku-produtos>
          </ul>
          <hr />
          <div class="row">
            <div class="col">
              <strong>Total Price: </strong>${{ itemTotal }} USD
            </div>
            <div class="col"><strong>Quantity: </strong>{{ qtdTotal }}</div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import skuProdutos from "../selecionados/variacoes/skuProdutos.vue";

export default {
  props: ["item"],
  components: {
    skuProdutos,
  },
  data() {
    return {};
  },
  computed: {
    itemTotal() {
      var init = 0;
      for (const key in this.item.variacao) {
        init +=
          parseFloat(this.item.variacao[key].quantidade) *
          this.item.variacao[key].preco;
      }
      return init.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    qtdTotal() {
      var init = 0;
      for (const key in this.item.variacao) {
        init += parseFloat(this.item.variacao[key].quantidade);
      }
      return init;
    },
    itemIndiv() {
      return this.item.preco.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecinado"];
    },
    produtoPageLink() {
      return "/products/" + this.item.codigo;
    },
  },
  methods: {
    remove() {
      this.$store.dispatch("carrinhos/removerDoCarrinho", {
        codigo: this.item.codigo,
      });
    },
  },
};
</script>

<style scoped>
#referencias {
  text-align: left;
  font-size: 15px;
}

#carrinho {
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  text-align: center;
  max-width: 45rem;
  margin-bottom: 8px;
  margin-top: 0px;
}

img {
  margin-bottom: 20px;
  width: 20rem;
  height: auto;
}

#imgbutton {
  background-color: #31b19a00;
  border: none;
  padding: 0;
  margin: 0;
}

.item__total {
  font-size: 15px;
  width: auto;
  padding: 0px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: right;
}

h3 {
  text-align: left;
}
</style>
