export default {
    requests(state) {
        return state.requests
    },
    statusPedido(state) {
        return state.statusPedido
    },
    falho(state) {
        return state.falho
    },
    carregamentoPedidos(state) {
        return state.carregamentoPedidos;
    }
}