export default {
  async realizarPedido(context, payload) {
    context.commit("carregamentoIniciado");

    const cnpj = payload.codigoempresa;
    const vendedor = "";
    const observacaoWeb = payload.observacao;
    const cliente = context.rootGetters["login/dadosCliente"].codigo;

    const entrega =
      context.rootGetters["login/dadosCliente"].endereco == null
        ? 0
        : context.rootGetters["login/dadosCliente"].endereco[0].codigo;

    const pedido = context.rootGetters["carrinhos/produtos"];

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();

    const codigovendedor = vendedor == "" ? 0 : vendedor;
    const observacaoWebCat = observacaoWeb == "" ? "Web" : observacaoWeb;

    const newRequest = {
      codigo_status: 1,
      data_inclusao: date,
      previsao_entrega: date,
      cliente: parseInt(cliente),
      codigo_endereco_entrega: parseInt(entrega),
      detalhes_financeiros: "",
      codigo_vendedor: parseInt(codigovendedor),
      observacao: observacaoWebCat,
      armazem: 1,
      usuario: "Adm",
      codigo: 0,
      itens: [],
    };

    for (const key in pedido) {
      for (const yek in pedido[key].variacao) {
        const newVar = {
          vtex_sku: pedido[key].variacao[yek].codigo_sku,
          quantidade: pedido[key].variacao[yek].quantidade,
          preco: pedido[key].variacao[yek].preco,
        };
        newRequest.itens.push(newVar);
      }
    }
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Venda/Post?token=sensualle&cnpj=${cnpj}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newRequest),
      }
    );
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Erro ao enviar mensagem."
      );
      throw error;
    }
    const responseData = await response.json();
    context.commit("statusPedido", responseData);
    context.commit("addRequest", newRequest);
    context.commit("carregamentoConcluido");
  },
};
