<template>
  <li class="product">
    <div v-if="carregamento" class="fullbody">
      <base-spinner></base-spinner>
    </div>
    <div class="container" v-if="!carregamento">
      <div
        class="row align-items-start justify-content-center"
        style="margin-top: 20px"
      >
        <div id="marginzero" class="col-md-5" v-if="this.temHeader">
          <carrossel-url
            v-for="header in carrosselUrlHeader"
            :key="header.key"
            :url="header.url_imagem"
          ></carrossel-url>
        </div>
        <img
          class="col-md-5"
          :src="produtoDado.url"
          alt="produto imagem"
          v-if="this.semHeader"
        />
        <ul class="col">
          <div>
            <h2>{{ produtoDado.nomeproduto }}</h2>
            <p>{{ produtoDado.referencia }} - {{ produtoDado.marca }}</p>
            <h4
              v-if="!this.variaPreco && this.permissaoUsuarioPage"
              class="itenstotal"
              style="color: var(--primary-color)"
            >
              ${{ precoindividual }} USD
            </h4>
            <br />

            <div v-if="carregarCores && this.permissaoUsuarioPage">
              <h4 class="itens">Complete Your Purchase:</h4>
              <hr />
              <li
                style="display: block; font-size: 20px"
                v-for="corTamanho in coresTamanhos"
                :key="corTamanho.codigo"
              >
                <div class="row align-items-center justify-content-center">
                  <div class="col" v-if="corTamanho.descricao_sku != ''">
                    {{ corTamanho.descricao_sku }}
                  </div>
                  <div class="col" v-if="this.variaPreco">
                    $
                    {{
                      corTamanho.preco.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      })
                    }}
                    USD
                  </div>
                  <div class="col" v-if="corTamanho.descricao_sku == ''">
                    {{ corTamanho.cor }}
                  </div>
                  <div class="col" v-if="corTamanho.descricao_sku == ''">
                    {{ corTamanho.tamanho }}
                  </div>
                  <div class="col">
                    <select
                      :value="produtosCalculados[corTamanho.codigo].qtd"
                      @input="
                        (e) => quantidade(e.target.value, corTamanho.codigo)
                      "
                      style="width: auto"
                      class="form-select"
                    >
                      <option
                        v-for="(numero, index) in corTamanho.saldo"
                        :key="index"
                        :value="index"
                      >
                        {{ index }}
                      </option>
                    </select>
                  </div>
                </div>
              </li>
            </div>
            <hr />
            <div v-if="this.mensagemUsuarioPage == 1">
              <h2>Por favor, aguarde a liberação de seu cadastro.</h2>
            </div>
            <div v-if="this.mensagemUsuarioPage == 2">
              <h2>Faça o login para visualizar o preço.</h2>
            </div>
          </div>
          <div v-if="this.permissaoUsuarioPage" class="row">
            <div class="col">
              <div v-if="(qtditem == 0 || qtditem == 1) && carregarCores">
                <h4 class="itensdesc">Total of {{ qtditem }} item for:</h4>
                <h4 class="itenstotal" style="color: var(--primary-color)">
                  ${{ somaprecototal }} USD
                </h4>
              </div>
              <div v-if="qtditem > 1 && carregarCores">
                <h4 class="itensdesc">Total of {{ qtditem }} items for:</h4>
                <h4 class="itenstotal" style="color: var(--primary-color)">
                  ${{ somaprecototal }} USD
                </h4>
              </div>
              <div v-if="!carregarCores">
                <h4 class="itensdesc">Product unavailable at the moment</h4>
              </div>
            </div>
            <div class="col-7">
              <button
                v-if="qtditem > 0 && preco > 0"
                @click="adicionarCarrinho"
              >
                BUY NOW
              </button>
            </div>
          </div>
        </ul>
      </div>
      <div v-if="this.permissaoUsuarioPage">
        <h1>Product Description</h1>
        <hr />
        <pre id="textodesc"><read-more
          more-str="Leia mais..."
          :text="detalhes"
          less-str="Leia menos..."
          :max-chars="250"
          style="text-align: justify; font-size: 20px; margin-top: 25px"
        ></read-more></pre>
      </div>
    </div>
    <base-dialog :open="adicionadoCarrinho" :title="'Success!'">
      <h2 class="lighter">Item added to cart!</h2>
      <button @click="$router.go(-1)">Continue Shopping</button>
      <router-link :to="carrinhosLink" v-slot="{ href, route, navigate }"
        ><button :href="href" @click="navigate">
          Go to My Cart {{ route.UserCarrinho }}
        </button></router-link
      >
    </base-dialog>
    <base-dialog :open="carrinhoVazio" :title="'Oooooooops!'">
      <h2 class="lighter">
        Selecione um produto ao carrinho antes de avançar.
      </h2>
      <button @click="continuarCompra">Voltar</button>
    </base-dialog>
  </li>
  <div class="container" v-if="!carregamento">
    <div id="produtoSimilar">
      <h1>Related Products</h1>
      <hr />
      <ul>
        <div
          class="row overflow-auto"
          style="flex-wrap: nowrap; flex-direction: row"
        >
          <item-similar
            class="col"
            v-for="produto in produtoSimilar"
            :key="produto.codigo"
            :nomeproduto="produto.nomeproduto"
            :url="produto.url"
            :detalhes="produto.detalhes"
            :preco="produto.preco"
            :saldo="produto.saldo"
            :codigo="produto.codigo"
            :referencia="produto.referencia"
          >
          </item-similar>
        </div>
      </ul>
    </div>
  </div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import carrosselUrl from "../components/selecionados/variacoes/carrosselUrl.vue";
import ItemSimilar from "@/components/produtos/ItemSimilar.vue";

import { mapState } from "vuex";

export default {
  components: {
    carrosselUrl,
    ItemSimilar,
  },
  data() {
    return {
      temHeader: false,
      semHeader: false,
      produtoSelecionados: {},
      carregamento: false,
      carregarCores: false,
      adicionadoCarrinho: false,
      variaPreco: false,
      precoCompara: null,
    };
  },
  computed: {
    ...mapState("produtos", ["produtoDado"]),
    ...mapState("produtos", ["carrosselUrlHeader"]),
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("produtos", ["categoria"]),
    ...mapState("produtos", ["produtoSimilar"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("login", ["usuarioLogado"]),
    ...mapState("empresa", ["permissaoUsuario"]),

    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    carrinhosLink() {
      return "/cart";
    },
    produtosLink() {
      return "/products";
    },
    coresTamanhos() {
      return this.$store.getters["produtos/variacoes"];
    },
    detalhes() {
      return this.produtoDado.detalhes;
    },
    produtoCodigo() {
      return this.$store.getters["loja/produtoSelecionado"];
    },
    preco() {
      return this.produtoDado.preco;
    },
    nomeproduto() {
      return this.produtoDado.nomeproduto;
    },
    somaprecototal() {
      var init = 0;
      for (const key in this.produtosCalculados) {
        if (this.produtosCalculados[key].qtd) {
          init +=
            parseFloat(this.produtosCalculados[key].qtd) *
            this.produtosCalculados[key].preco;
        }
      }
      return init.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    precoindividual() {
      return this.preco.toLocaleString("pt-br", { minimumFractionDigits: 2 });
    },
    qtditem() {
      var init = 0;
      for (const key in this.produtosCalculados) {
        if (this.produtosCalculados[key].qtd) {
          init += parseInt(this.produtosCalculados[key].qtd);
        }
      }
      return init;
    },
    produtosCalculados() {
      if (this.coresTamanhos === undefined) {
        return;
      } else
        return this.coresTamanhos.reduce((result, current) => {
          return {
            ...result,
            [current.codigo]: {
              qtd: parseInt(this.produtoSelecionados[current.codigo]),
              tamanho: current.tamanho,
              codigo_sku: current.codigo,
              codigo_cor: current.codigo_cor,
              descricao_sku: current.descricao_sku,
              url: current.url,
              codigo_prod: this.produtoCodigo,
              preco: current.preco,
            },
          };
        }, {});
    },
    permissaoUsuarioPage() {
      if (this.permissaoUsuario == "2") {
        return true;
      } else if (this.permissaoUsuario == "1" && this.usuarioLogado == true) {
        if (this.dadosCliente.aprovado == "S") {
          return true;
        }
        return false;
      } else return false;
    },
    mensagemUsuarioPage() {
      if (this.permissaoUsuario == "2") {
        return 0;
      } else if (this.permissaoUsuario == "1" && this.usuarioLogado == true) {
        if (this.dadosCliente.aprovado == "S") {
          return 0;
        }
        return 1;
      } else return 2;
    },
  },

  methods: {
    async carregarProdutoDados() {
      this.carregamento = true;
      await this.$store.dispatch("produtos/carregarProdutoDado");
      this.$store.dispatch("produtos/carregarSimilares", {
        primeiroNome: this.produtoDado.primeiroNome,
      });
      this.carregamento = false;
    },
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarCarrossel() {
      this.carregamento = true;

      await this.$store.dispatch("produtos/carregarCarrossel", {
        codigo: this.produtoCodigo,
      });

      if (this.carrosselUrlHeader.length > 0) {
        this.temHeader = true;
        this.carrosselUrlHeader[0].key++;
      } else {
        this.semHeader = true;
      }
      this.carregamento = false;
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    adicionarCarrinho() {
      if (this.qtditem > 0) {
        let qtd = 0;

        for (const key in this.produtosCalculados) {
          if (!this.produtosCalculados[key].qtd) {
            qtd == qtd;
          } else qtd += parseInt(this.produtosCalculados[key].qtd);
        }

        this.$store.dispatch("produtos/produtosCalculados", {
          produtoSelecionado: this.produtosCalculados,
        });

        this.$store.dispatch("carrinhos/produtosCalculados", {
          produtoSelecionado: this.produtosCalculados,
        });

        this.$store.dispatch("carrinhos/adicionarCarrinho", {
          nomeproduto: this.nomeproduto,
        });

        this.$store.dispatch("produtos/resetVariacoes");
        this.adicionadoCarrinho = true;
      } else {
        this.carrinhoVazio = true;
      }
    },
    async variacoes() {
      await this.$store.dispatch("produtos/variacao", {
        codigo: this.produtoCodigo,
      });
      for (const key in this.$store.getters["produtos/variacoes"]) {
        if (this.precoCompara == null) {
          this.precoCompara =
            this.$store.getters["produtos/variacoes"][key].preco;
        } else if (
          this.precoCompara ==
          this.$store.getters["produtos/variacoes"][key].preco
        ) {
          this.variaPreco = false;
        } else {
          this.variaPreco = true;
        }
      }
      if (this.$store.getters["produtos/variacoes"].length > 0) {
        this.carregarCores = true;
      } else {
        this.carregarCores = false;
      }
    },
    top() {
      window.scrollTo(0, 0);
    },
    quantidade(qtd, codigo) {
      this.produtoSelecionados[codigo] = qtd;
    },
    carregarPermissaoUsuario() {
      this.$store.dispatch("empresa/permissaoUsuario");
    },
  },
  created() {
    this.carregarProdutoDados();
    this.variacoes();
    this.carregarCarrossel();
    this.carregarRodape();
    this.carregarCategorias();
    this.carregarPermissaoUsuario();
    this.top();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--primary-color) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
  border: 3px solid #ffffff;
}
#produtoSimilar {
  margin-top: 70px;
}

#textodesc {
  font-family: system-ui, "Segoe UI", "Helvetica Neue", "Noto Sans",
    "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

h1 {
  font-size: 30px;
  color: var(--primary-color);
  margin: 25px 40px 25px 10px;
}

li {
  display: inline;
  margin: 0px;
  max-width: 60rem;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: 1rem 1rem;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

button:hover,
button:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.itens {
  font-size: 20px;
  text-align: left;
}

.lighter {
  font-weight: lighter;
}

.itensselec {
  padding: 40px;
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
}

.itensdesc {
  font-size: 20px;
  font-weight: lighter;
  text-align: left;
}

.itenstotal {
  font-size: 35px;
  color: var(--primary-color);
  text-align: left;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;

  margin-top: 80px;
}

.fullbody {
  min-height: 100vh;
}
</style>
