<template>
  <div class="container">
    <div id="variacao" class="row">
      <div class="col-sm-8">
        <ul>
          <li><strong>Reference:</strong> {{ referencia }}</li>
          <li><strong>Product:</strong> {{ nomeproduto }}</li>
          <li><strong>Description:</strong> {{ descSku }}</li>
          <div class="row">
            <div class="col-sm-7">
              <li><strong>Unit Price:</strong> ${{ precoIndividual }} USD</li>
            </div>
            <div class="col">
              <li><strong>Quantity:</strong> {{ quantidade }}</li>
            </div>
          </div>
          <li><strong>Total:</strong> ${{ precoTotalmente }} USD</li>
        </ul>
      </div>
      <div class="col">
        <img :src="url" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "referencia",
    "nomeproduto",
    "descSku",
    "preco",
    "quantidade",
    "precoTotal",
    "url",
  ],
  data() {
    return {};
  },
  computed: {
    precoTotalmente() {
      return (this.preco * this.quantidade).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    precoIndividual() {
      return this.preco.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0;
  margin: 0;
}
#variacao {
  border-top: 1px solid rgba(0, 0, 0, 0.405);
  margin: 20px auto;
}

ul {
  list-style: none;
  margin: 1rem 0rem;
  padding: 0;
}

li {
  font-size: 15px;
  text-align: left;
}

p {
  margin: 2rem auto;
}

img {
  width: 7rem;
  margin: 1rem 0rem;
  border-radius: 20px;
}
</style>
