export default {
  async carregarProdutos(context, payload) {
    const pagina = context.rootGetters["loja/paginacao"];
    const ordem = payload.ordem;

    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/ProdutosCatalogo/Get/0?token=sensualle&limite=12&pagina=${pagina}&pOrdenacao=${ordem}`
    );

    const responseData = await response.json();

    const produtos = [];
    if (responseData.length != 0) {
      for (const key in responseData) {
        const produto = {
          nomeproduto: responseData[key].nomeproduto,
          url: responseData[key].url,
          detalhes: responseData[key].detalhes,
          codigo: responseData[key].codigo,
          preco: responseData[key].preco,
          saldo: responseData[key].saldo,
          referencia: responseData[key].referencia,
        };
        produtos.push(produto);
      }
      context.commit("carregarProdutos", produtos);
    } else {
      context.commit("acabouprodutos", "acabou");
    }
  },

  async carregarSimilares(context, payload) {
    const primeiroNome = payload.primeiroNome;
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/ProdutosCatalogo/Get/0?token=sensualle&limite=12&pBusca=${primeiroNome}`
    );

    const responseData = await response.json();
    const produtos = [];
    if (responseData.length != 0) {
      for (const key in responseData) {
        const produto = {
          nomeproduto: responseData[key].nomeproduto,
          url: responseData[key].url,
          detalhes: responseData[key].detalhes,
          codigo: responseData[key].codigo,
          preco: responseData[key].preco,
          saldo: responseData[key].saldo,
          referencia: responseData[key].referencia,
        };
        produtos.push(produto);
      }
      context.commit("carregarSimilar", produtos);
    }
  },

  async variacao(context, payload) {
    const codigoProduto = payload.codigo;

    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/SKUCatalogo/Get?token=sensualle&pProduto=${codigoProduto}`
    );

    const responseData = await response.json();
    const coresTamanhos = [];

    for (let i = 0, len = responseData.length; i < len; i++) {
      if (responseData[i].imagem.length != 0) {
        if (responseData[i].saldo > 0) {
          const variacoes = {
            cor: responseData[i].cor == "*UN" ? "" : responseData[i].cor,
            tamanho:
              responseData[i].tamanho == "*UN" ? "" : responseData[i].tamanho,
            codigo: responseData[i].codigo_sku,
            descricao_sku: responseData[i].descricao_sku,
            url: responseData[i].imagem[0].url_imagem,
            codigo_cor: responseData[i].codigo_cor,
            preco: parseFloat(responseData[i].preco.replace(",", ".")),
            saldo: parseInt(responseData[i].saldo) + 1,
          };
          coresTamanhos.push(variacoes);
        }
      } else {
        if (responseData[i].saldo > 0) {
          const variacoes = {
            cor: responseData[i].cor == "*UN" ? "" : responseData[i].cor,
            tamanho:
              responseData[i].tamanho == "*UN" ? "" : responseData[i].tamanho,
            codigo: responseData[i].codigo_sku,
            descricao_sku: responseData[i].descricao_sku,
            codigo_cor: responseData[i].codigo_cor,
            preco: parseFloat(responseData[i].preco.replace(",", ".")),
            saldo: parseInt(responseData[i].saldo) + 1,
          };
          coresTamanhos.push(variacoes);
        }
      }
    }
    context.commit("carregarVariacoes", coresTamanhos);
  },

  resetVariacoes(context) {
    context.commit("resetVariacoes");
  },

  resetProdutos(context) {
    context.commit("resetProdutos");
  },

  produtosCalculados(context, payload) {
    context.commit("carregarCalculados", payload.produtoSelecionado);
  },

  resetSelect(context) {
    context.commit("resetSelect");
  },

  isProdutoReady(context) {
    context.commit("isCarregado");
  },
  isProdutoLoading(context) {
    context.commit("isNotCarregado");
  },

  async carregarCarrossel(context, payload) {
    const codigoProduto = payload.codigo;

    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/SKUCatalogo/Get?token=sensualle&pProduto=${codigoProduto}`
    );
    const responseData = await response.json();

    const CarrosselUrl = [];
    const CarrosselUrlHeader = [];
    const uniqueURLs = [];

    let i = 0;
    let c = 1;
    let aux = 0;

    for (const yek in responseData[0].imagem) {
      const url_imagem = responseData[0].imagem[yek].url_imagem;

      if (aux == 0 && yek == 0) {
        const imagem = {
          url_imagem,
          slide_to: i,
          aria_label: "Slide " + c,
          key: 0,
        };
        i++;
        c++;
        CarrosselUrlHeader.push(imagem);
        uniqueURLs.push(url_imagem);
        aux++;
      } else {
        // Check if the URL is not in the uniqueURLs array before pushing
        if (!uniqueURLs.includes(url_imagem)) {
          const imagem = {
            url_imagem,
            slide_to: i,
            aria_label: "Slide " + c,
          };
          i++;
          c++;
          CarrosselUrl.push(imagem);
          // Add the URL to the uniqueURLs array to avoid duplicates
          uniqueURLs.push(url_imagem);
        }
      }
    }

    context.commit("carregarCarrossel", CarrosselUrl);
    context.commit("carregarCarrosselHeader", CarrosselUrlHeader);
  },

  resertCarousel(context) {
    context.commit("resetcarregarCarrossel");
    context.commit("resetcarregarCarrosselHeader");
  },

  async carregarProdutoDado(context) {
    const codigoProduto = context.rootGetters["loja/produtoSelecionado"];

    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/ProdutosCatalogo/Get/${codigoProduto}?token=sensualle`
    );

    const responseData = await response.json();

    if (responseData.length != 0) {
      for (const key in responseData) {
        let nome = responseData[key].nomeproduto.split(" ");
        let primeiroNome = nome[0];
        const produtoDado = {
          nomeproduto: responseData[key].nomeproduto,
          primeiroNome: primeiroNome,
          url: responseData[key].url,
          detalhes: responseData[key].detalhes,
          codigo: responseData[key].codigo,
          preco: responseData[key].preco,
          referencia: responseData[key].referencia,
          marca: responseData[key].Marca,
        };
        context.commit("carregarProdutoDado", produtoDado);
      }
    } else {
      context.commit("acabouprodutos", "acabou");
    }
  },

  async carregarCategorias(context) {
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Categoria/Get/0?token=sensualle&pPai=0&pSomentePAI=true`
    );
    const responseData = await response.json();

    const categoriasPromises = Object.keys(responseData).map(async (key) => {
      const categoria = responseData[key];
      const responseSub = await fetch(
        `https://apiciriuserp.com.br:2083/api/Categoria/Get/0?token=sensualle&pPai=${categoria.codigo}&pSomentePAI=false`
      );
      const responseDataSub = await responseSub.json();
      return {
        categoria: categoria.nome,
        codigoCategoria: categoria.codigo,
        filhas: responseDataSub,
      };
    });

    const categorias = await Promise.all(categoriasPromises);
    context.commit("carregarCategoria", categorias);
  },

  async carregarCategoriaSelecionada(context, payload) {
    const categoria = context.rootGetters["loja/categoriaSelecionada"];
    const ordem = payload.ordem;
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/ProdutosCatalogo/Get/0?token=sensualle&categoria=${categoria}&pOrdenacao=${ordem}`
    );

    const responseData = await response.json();
    const categorias = [];
    if (responseData.length != 0 && categoria != undefined) {
      for (const key in responseData) {
        const categoria = {
          nomeproduto: responseData[key].nomeproduto,
          categoria: responseData[key].Categoria,
          url: responseData[key].url,
          detalhes: responseData[key].detalhes,
          codigo: responseData[key].codigo,
          preco: responseData[key].preco,
          saldo: responseData[key].saldo,
          referencia: responseData[key].referencia,
        };
        categorias.push(categoria);
      }
      context.commit("carregarCategoriaSelecionada", categorias);
    } else if (categoria != undefined) {
      context.commit("carregarCategoriaSelecionada", null);
      context.commit("acabouprodutos", "acabou");
    }
  },
  async carregarBusca(context, payload) {
    const busca = payload.busca;
    context.commit("carregamento", true);

    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/ProdutosCatalogo/Get/0?token=sensualle&pBusca=${busca}`
    );
    const responseData = await response.json();

    const termoBuscados = [];
    if (responseData.length != 0) {
      for (const key in responseData) {
        const termo = {
          nomeproduto: responseData[key].nomeproduto,
          categoria: responseData[key].Categoria,
          url: responseData[key].url,
          detalhes: responseData[key].detalhes,
          codigo: responseData[key].codigo,
          preco: responseData[key].preco,
          saldo: responseData[key].saldo,
          referencia: responseData[key].referencia,
        };
        termoBuscados.push(termo);
      }
      context.commit("carregarBuscaBuscada", termoBuscados);
      context.commit("carregamento", false);
    } else {
      context.commit("carregarBuscaVazia");
      context.commit("carregamento", false);
    }
  },
  async carregarBarCode(context, payload) {
    const barCode = payload.barCode;
    context.commit("carregamento", true);
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/ProdutosCatalogo/Get/0?token=sensualle&pCodigoDeBarras=${barCode}`
    );
    const responseData = await response.json();
    let termoBuscados = [];
    if (responseData.length != 0) {
      for (const key in responseData) {
        const termo = {
          nomeproduto: responseData[key].nomeproduto,
          categoria: responseData[key].Categoria,
          url: responseData[key].url,
          detalhes: responseData[key].detalhes,
          codigo: responseData[key].codigo,
          preco: responseData[key].preco,
          saldo: responseData[key].saldo,
          referencia: responseData[key].referencia,
        };
        termoBuscados = termo;
      }
      context.commit("carregarBuscaBuscada", termoBuscados);
      context.commit("carregamento", false);
    } else {
      context.commit("carregarBuscaVazia");
      context.commit("carregamento", false);
    }
  },
};
