<template>
  <div>
    <StreamBarcodeReader
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader,
  },
  computed: {
    ...mapState("produtos", ["termoBuscado"]),
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },

    produtoPageLink() {
      return "/products/" + this.termoBuscado.codigo;
    },
  },
  methods: {
    async onDecode(result) {
      console.log(result);
      await this.$store.dispatch("produtos/carregarBarCode", {
        barCode: result,
      });
      this.$router.push(this.produtoPageLink);
    },
  },
};
</script>
