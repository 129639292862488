<template>
  <li>
    <div class="container">
      <div class="row">
        <div class="col">
          <strong>Description:</strong> {{ variacoes.descricao_sku }}
        </div>
        <div class="col">
          <strong>Quantity:</strong> {{ variacoes.quantidade }}
        </div>
        <div class="col"><strong>Price: </strong>${{ precoIndividual }} USD</div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ["variacoes"],
  data() {
    return {};
  },
  computed: {
    precoIndividual() {
      return this.variacoes.preco.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>

<style scoped>
li {
  padding: 0px;
}

.col {
  padding: 0px;
}
</style>
