<template>
  <div v-if="!cadastrofinalizado">
    <form @submit.prevent="submitForm" onkeydown="return event.key != 'Enter';">
      <div class="row d-flex justify-content-center">
        <div class="form-cadastro col-md">
          <h2>Register</h2>
          <div
            class="mb-3 col-md-7"
            :class="{ invalid: !nomeCompleto.isValid }"
          >
            <label for="nomeCompleto" class="form-label">Name*:</label>
            <input
              type="text"
              id="nomeCompleto"
              v-model.trim="nomeCompleto.val"
              class="form-control"
              @blur="clearValidity('nomeCompleto')"
            />
            <p v-if="!nomeCompleto.isValid">Invalid name.</p>
          </div>
          <div class="mb-3 col-md-6" :class="{ invalid: !email.isValid }">
            <label for="email" class="form-label">Email*:</label>
            <input
              type="email"
              id="email"
              class="form-control"
              v-model.trim="email.val"
              @blur="clearValidity('email')"
            />
            <p v-if="!email.isValid">Invalid email.</p>
          </div>
          <div class="row">
            <div
              class="mb-3 col-md-6"
              :class="{ invalid: !senha.isValid || !senha.isEqual }"
            >
              <label for="senha" class="form-label">Password*:</label>
              <input
                type="password"
                id="senha"
                class="form-control"
                v-model.trim="senha.val"
                @blur="clearValidity('senha')"
              />
              <p v-if="!senha.isValid">
                Invalid password (Must contain between 4 to 20 characters).
              </p>
              <p v-if="!senha.isEqual">Passwords didn't match.</p>
            </div>
            <div
              class="mb-3 col-md-6"
              :class="{
                invalid: !confirmasenha.isValid || !confirmasenha.isEqual,
              }"
            >
              <label for="confirmarsenha" class="form-label"
                >Confirm Password*:</label
              >
              <input
                type="password"
                id="confirmarsenha"
                class="form-control"
                v-model.trim="confirmasenha.val"
                @blur="clearValidity('confirmasenha')"
              />
              <p v-if="!senha.isValid">
                Invalid password (Must contain between 4 to 20 characters).
              </p>
              <p v-if="!senha.isEqual">Passwords didn't match.</p>
            </div>
          </div>
          <!-- <div class="margin">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="cpf"
                name="cpfOucnpj"
                value="cpf"
                v-model="cpfOucnpj.val"
                :checked="isSelect == 1"
              />
              <label style="display: inline" class="form-check-label" for="cpf"
                >Individual</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="cnpj"
                name="cpfOucnpj"
                value="cnpj"
                v-model="cpfOucnpj.val"
                :checked="isSelect == 2"
              />
              <label style="display: inline" class="form-check-label" for="cnpj"
                >Legal Entity</label
              >
            </div>
          </div> -->
          <!-- <div class="row">
            <div
              class="mb-3 col-md-6"
              :class="{ invalid: !cadastroPessoaFisica.isValid }"
              v-if="cpfOucnpj.val === 'cpf'"
            >
              <label for="cadastroPessoaFisica" class="form-label">CPF*:</label>
              <input
                type="text"
                id="cadastroPessoaFisica"
                v-model.trim="cadastroPessoaFisica.val"
                class="form-control"
                v-maska="'###.###.###-##'"
                @blur="clearValidity('cadastroPessoaFisica')"
              />
              <p v-if="!cadastroPessoaFisica.isValid">CPF inválido.</p>
            </div>
          </div> -->

          <!-- <div class="row">
            <div
              class="mb-3 col"
              :class="{ invalid: !cadastroPessoaJuridica.isValid }"
              v-if="cpfOucnpj.val === 'cnpj'"
            >
              <label for="cadastroPessoaJuridica">CNPJ*:</label>
              <input
                type="text"
                id="cadastroPessoaJuridica"
                v-model.trim="cadastroPessoaJuridica.val"
                class="form-control"
                v-maska="'##.###.###/####-##'"
                @blur="clearValidity('cadastroPessoaJuridica')"
              />
              <p v-if="!cadastroPessoaJuridica.isValid">CNPJ inválido.</p>
            </div>
            <div class="mb-3 col-md" v-if="cpfOucnpj.val === 'cnpj'">
              <label for="inscricaoEstatual" class="form-label"
                >Inscrição Estadual:</label
              >
              <input
                type="text"
                id="inscricaoEstatual"
                v-model.trim="inscricaoEstatual.val"
                class="form-control"
              />
            </div>
          </div>
          <div
            class="mb-3 col-md-8"
            :class="{ invalid: !razaoSocial.isValid }"
            v-if="cpfOucnpj.val === 'cnpj'"
          >
            <label for="razaoSocial" class="form-label">Razão Social*:</label>
            <input
              type="text"
              id="razaoSocial"
              class="form-control"
              v-model.trim="razaoSocial.val"
              @blur="clearValidity('razaoSocial')"
            />
            <p v-if="!razaoSocial.isValid">Razão Social inválido.</p>
          </div> -->
          <div class="row">
            <div class="mb-3 col" :class="{ invalid: !numeroCelular.isValid }">
              <label for="numeroCelular" class="form-label"
                >Mobile Phone*:</label
              >
              <input
                type="tel"
                id="numeroCelular"
                class="form-control"
                v-model.trim="numeroCelular.val"
                v-maska="'(###) ####-####'"
                @blur="clearValidity('numeroCelular')"
              />
              <p v-if="!numeroCelular.isValid">Invalid Mobile Phone.</p>
            </div>
            <div class="mb-3 col" :class="{ invalid: !numeroTelefone.isValid }">
              <label for="numeroTelefone" class="form-label">Home Phone:</label>
              <input
                type="tel"
                id="numeroTelefone"
                class="form-control"
                v-model.trim="numeroTelefone.val"
                v-maska="'(###) ####-####'"
                @blur="clearValidity('numeroTelefone')"
              />
              <p v-if="!numeroTelefone.isValid">Invalid Home Phone.</p>
            </div>
          </div>
        </div>
        <div class="form-entrega col-md" v-if="global == '1'">
          <h2>Address</h2>
          <div
            class="mb-3 col-md-5"
            :class="{ invalid: !enderecoPostal.isValid }"
          >
            <label for="enderecoPostal" class="form-label">Zip Code*:</label>
            <input
              type="text"
              id="enderecoPostal"
              class="form-control"
              v-model.trim="enderecoPostal.val"
              v-maska="'#########'"
              @blur="clearValidity('enderecoPostal')"
            />
            <p v-if="!enderecoPostal.isValid">Invalid Postal Code.</p>
            <div v-if="isLoading"><base-spinner></base-spinner></div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-8" :class="{ invalid: !endereco.isValid }">
              <label for="endereco" class="form-label">Address Line 1*:</label>
              <input
                type="text"
                id="endereco"
                class="form-control"
                placeholder="House number and street name"
                v-model.trim="endereco.val"
                @blur="clearValidity('endereco')"
              />
              <p v-if="!endereco.isValid">Endereço inválido.</p>
            </div>
            <!-- <div
              class="mb-3 col-4"
              :class="{ invalid: !enderecoNumero.isValid }"
            >
              <label for="enderecoNumero" class="form-label">Número*:</label>
              <input
                type="enderecoNumero"
                id="enderecoNumero"
                v-maska="'#######'"
                class="form-control"
                v-model.trim="enderecoNumero.val"
                @blur="clearValidity('enderecoNumero')"
              />
              <p v-if="!enderecoNumero.isValid">Número inválido.</p>
            </div> -->
          </div>
          <div class="row">
            <!-- <div
              class="mb-3 col-6"
              :class="{ invalid: !enderecoComplemento.isValid }"
            >
              <label for="enderecoComplemento" class="form-label"
                >Address Line 2:</label
              >
              <input
                type="text"
                id="enderecoComplemento"
                class="form-control"
                v-model.trim="enderecoComplemento.val"
                @blur="clearValidity('enderecoComplemento')"
              />
              <p v-if="!enderecoComplemento.isValid">Complemento inválido.</p>
            </div> -->
            <!-- <div
              class="mb-3 col-6"
              :class="{ invalid: !enderecoReferencia.isValid }"
            >
              <label for="enderecoReferencia" class="form-label"
                >Referência:</label
              >
              <input
                type="text"
                id="enderecoReferencia"
                class="form-control"
                v-model.trim="enderecoReferencia.val"
                @blur="clearValidity('enderecoReferencia')"
              />
              <p v-if="!enderecoReferencia.isValid">Referência inválida.</p>
            </div> -->
          </div>
          <div
            class="mb-3 col-md-8"
            :class="{ invalid: !enderecoBairro.isValid }"
          >
            <label for="enderecoBairro" class="form-label"
              >Address Line 2:</label
            >
            <input
              type="text"
              id="enderecoBairro"
              placeholder="Apartment, suit, unit, etc. (optional)"
              class="form-control"
              v-model.trim="enderecoBairro.val"
              @blur="clearValidity('enderecoBairro')"
            />
            <p v-if="!enderecoBairro.isValid">Invalid Address.</p>
          </div>
          <div class="row">
            <div class="mb-3 col-6" :class="{ invalid: !Cidade.isValid }">
              <label for="Cidade" class="form-label">Town/City*:</label>
              <input
                type="text"
                id="Cidade"
                class="form-control"
                v-model.trim="Cidade.val"
                @blur="clearValidity('Cidade')"
              />
              <p v-if="!Cidade.isValid">Invalid City.</p>
            </div>
            <div class="mb-3 col-4" :class="{ invalid: !Estado.isValid }">
              <label for="Estado" class="form-label">State/Province*:</label>
              <input
                type="text"
                id="state"
                class="form-control"
                v-model.trim="statei.val"
                @blur="clearValidity('statei')"
              />
              <p v-if="!Estado.isValid">Invalid State/Province.</p>
            </div>
          </div>
        </div>
      </div>
      <p v-if="!formIsValid">Please complete the fields to continue.</p>

      <div class="button-control">
        <br />
        <button @click="validateForm">Register</button>
      </div>
    </form>
  </div>
  <div v-else class="fullbody">
    <h1
      style="margin: 20px"
      v-if="statusCliente == 'Cliente cadastrado com sucesso.' && !carregamento"
    >
      Congratulations, you are now registered!
      <br />
    </h1>
    <h1
      v-if="statusCliente != 'Cliente cadastrado com sucesso.' && !carregamento"
    >
      Sorry, something went wrong!
      <br />
    </h1>
    <div
      class="centrali"
      v-if="statusCliente != 'Cliente cadastrado com sucesso.' && !carregamento"
    >
      <router-link :to="minhaConta" v-slot="{ href, route, navigate }">
        <button :href="href" @click="navigate">
          Go to my account {{ route.MinhaConta }}
        </button>
      </router-link>
    </div>
  </div>
  <br />
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      carregamento: false,
      cadastrofinalizado: false,
      isLoading: false,
      isSelect: "",
      nomeCompleto: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      senha: {
        val: "",
        isValid: true,
        isEqual: true,
      },
      confirmasenha: {
        val: "",
        isValid: true,
        isEqual: true,
      },
      cadastroPessoaFisica: {
        val: "",
        isValid: true,
      },
      cadastroPessoaJuridica: {
        val: "",
        isValid: true,
      },
      numeroCelular: {
        val: "",
        isValid: true,
      },
      numeroTelefone: {
        val: "",
        isValid: true,
      },
      enderecoPostal: {
        val: "",
        isValid: true,
      },
      endereco: {
        val: "",
        isValid: true,
      },
      enderecoNumero: {
        val: "",
        isValid: true,
      },
      enderecoComplemento: {
        val: "",
        isValid: true,
      },
      enderecoReferencia: {
        val: "",
        isValid: true,
      },
      enderecoBairro: {
        val: "",
        isValid: true,
      },
      Cidade: {
        val: "",
        isValid: true,
      },
      Estado: {
        val: "",
        isValid: true,
      },
      cpfOucnpj: {
        val: "cpf",
        isValid: true,
      },
      razaoSocial: {
        val: "",
        isValid: true,
      },
      inscricaoEstatual: {
        val: "",
        isValid: true,
      },
      observacao: {
        val: "",
        isValid: true,
      },
      statei: {
        val: "",
        isValid: true,
      },
      formIsValid: true,
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("empresa", ["global"]),

    resumoCompras() {
      return this.$store.getters["carrinhos/produtos"];
    },
    carrinhoTotal() {
      return this.$store.getters["carrinhos/somaTotal"].toLocaleString(
        "pt-br",
        { minimumFractionDigits: 2 }
      );
    },
    itemTotal() {
      return (this.resumoCompra.preco * this.item.qtd).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    cepDados() {
      return this.$store.getters["form/cepDados"];
    },
    clienteDados() {
      return this.$store.getters["login/dadosCliente"];
    },
    statusCliente() {
      return this.$store.getters["form/status"];
    },
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    minhaConta() {
      return "/products/minhaconta";
    },
    meusPedidosLink() {
      return "/products/meuspedidos";
    },
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarGlobal() {
      await this.$store.dispatch("empresa/carregarGlobal");
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      if (this.nomeCompleto.val == "") {
        this.nomeCompleto.isValid = false;
        this.formIsValid = false;
        this.top();
      }
      if (this.email.val == "" || !this.email.val.includes("@")) {
        this.email.isValid = false;
        this.formIsValid = false;
        this.top();
      }
      if (
        this.senha.val.length < 4 ||
        this.senha.val.length > 20 ||
        this.senha.val == ""
      ) {
        this.senha.isValid = false;
        this.formIsValid = false;
        this.top();
      }
      if (
        this.confirmasenha.val.length < 4 ||
        this.confirmasenha.val.length > 20 ||
        this.confirmasenha.val == ""
      ) {
        this.confirmasenha.isValid = false;
        this.formIsValid = false;
        this.top();
      }
      if (this.confirmasenha.val != this.senha.val) {
        this.confirmasenha.isEqual = false;
        this.senha.isEqual = false;
        this.formIsValid = false;
        this.top();
      }
      /* if (this.cpfOucnpj.val === "cpf") {
        if (
          this.cadastroPessoaFisica.val == "" ||
          this.cadastroPessoaFisica.val.length < 14
        ) {
          this.cadastroPessoaFisica.isValid = false;
          this.formIsValid = false;
          this.top();
        }
      } else {
        if (
          this.cadastroPessoaJuridica.val == "" ||
          this.cadastroPessoaJuridica.val.length < 18
        ) {
          this.cadastroPessoaJuridica.isValid = false;
          this.formIsValid = false;
          this.top();
        }
        if (this.razaoSocial.val == "") {
          this.razaoSocial.isValid = false;
          this.formIsValid = false;
          this.top();
        }
      } */

      if (this.numeroCelular.val == "" || this.numeroCelular.val.length < 13) {
        this.numeroCelular.isValid = false;
        this.formIsValid = false;
        this.top();
      }
      if (global == "1") {
        if (
          this.enderecoPostal.val == "" ||
          this.enderecoPostal.val.length < 9
        ) {
          this.enderecoPostal.isValid = false;
          this.formIsValid = false;
          this.top();
        }
        if (this.endereco.val == "") {
          this.endereco.isValid = false;
          this.formIsValid = false;
          this.top();
        }
        if (this.enderecoNumero.val == "") {
          this.enderecoNumero.isValid = false;
          this.formIsValid = false;
          this.top();
        }
        if (this.enderecoBairro.val == "") {
          this.enderecoBairro.isValid = false;
          this.formIsValid = false;
          this.top();
        }
        if (this.Cidade.val == "") {
          this.Cidade.isValid = false;
          this.formIsValid = false;
          this.top();
        }
        if (this.Estado.val == "") {
          this.Estado.isValid = false;
          this.formIsValid = false;
          this.top();
        }
        if (this.statei.val == "") {
          this.statei.isValid = false;
          this.formIsValid = false;
          this.top();
        }
      }
      if (this.cpfOucnpj.val == "") {
        this.cpfOucnpj.isValid = false;
        this.formIsValid = false;
        this.top();
      }
    },
    async submitForm() {
      if (this.formIsValid == true) {
        (this.cadastrofinalizado = true), (this.carregamento = true);
        this.isLoading = true;
        await this.$store.dispatch("form/cadastrarCliente", {
          empresacnpj: this.empresarodape.cnpj,
          nome: this.nomeCompleto.val,
          razao: this.razaoSocial.val,
          cnpj: this.cadastroPessoaJuridica.val,
          cpf: this.cadastroPessoaFisica.val,
          senha: this.senha.val,
          celular: this.numeroCelular.val,
          telefone: this.numeroTelefone.val,
          contato: this.nomeCompleto.val,
          ie: this.inscricaoEstatual.val,
          email: this.email.val,
          cidade: this.Cidade.val,
          logradouro: this.endereco.val,
          complemento: this.enderecoBairro.val,
          numero: this.enderecoNumero.val,
          //complemento: this.enderecoComplemento.val,
          cep: this.enderecoPostal.val,
          uf: this.statei.val,
        });
        if (this.cpfOucnpj.val === "cpf") {
          await this.$store.dispatch("login/carregarCliente", {
            codigo: this.cadastroPessoaFisica.val,
            senha: this.senha.val,
          });
        } else {
          await this.$store.dispatch("login/carregarCliente", {
            codigo: this.cadastroPessoaJuridica.val,
            senha: this.senha.val,
          });
        }
        this.isLoading = false;
        this.top();
        this.carregamento = false;
      }
    },
    /* async consultarCep() {
      this.isLoading = true;
      await this.$store.dispatch("form/buscarCep", this.enderecoPostal.val);
      this.isLoading = false;
      this.endereco.val = this.cepDados.logradouro;
      this.enderecoBairro.val = this.cepDados.bairro;
      this.Cidade.val = this.cepDados.localidade;
      this.Estado.val = this.cepDados.uf;
    },
    setDadosCliente() {
      if (this.clienteDados != null) {
        this.nomeCompleto.val = this.clienteDados.nome;
        this.email.val = this.clienteDados.email;
        this.cadastroPessoaFisica.val = this.clienteDados.cpf;
        this.cadastroPessoaJuridica.val = this.clienteDados.cnpj;
        this.senha.val = this.clienteDados.senha;
        this.confirmasenha.val = this.clienteDados.senha;
        this.numeroCelular.val = this.clienteDados.celular;
        this.numeroTelefone.val = this.clienteDados.telefone;
        this.razaoSocial.val = this.clienteDados.razao;
        this.inscricaoEstatual.val = this.clienteDados.IE;
        for (const key in this.clienteDados.endereco) {
          this.enderecoPostal.val = this.clienteDados.endereco[key].cep;
          this.endereco.val = this.clienteDados.endereco[key].logradouro;
          this.enderecoNumero.val = this.clienteDados.endereco[key].numero;
          this.enderecoComplemento.val =
            this.clienteDados.endereco[key].complemento;
          this.enderecoBairro.val = this.clienteDados.endereco[key].bairro;
          this.Cidade.val = this.clienteDados.endereco[key].cidade;
          this.Estado.val = this.clienteDados.endereco[key].uf;
        }
        if (this.clienteDados.cnpj === "") {
          this.isSelect = 1;
          this.cpfOucnpj.val = "cpf";
        } else {
          this.isSelect = 2;
          this.cpfOucnpj.val = "cnpj";
        }
      }
    }, */
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.carregarGlobal();
    this.carregarRodape();
    this.carregarCategorias();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.centralizador {
  text-align: center;
}

#resumoItem {
  border: 1px solid rgba(0, 0, 0, 0.322);
  border-radius: 20px;
  max-width: 50rem;
  text-align: center;
  margin: auto;
  align-items: center;
  font-size: 15px;
  padding: 20px;
}

ul {
  list-style: none;
  text-align: left;
}

li {
  text-align: left;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

h1 {
  color: #292929;
  text-align: center;
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
}

h2 {
  text-align: center;
  background: var(--primary-color);
  color: white;
  padding: 10px;
  border: 1px solid black;
  margin: -21px -21px 0px -21px;
  border-radius: 10px 10px 0px 0px;
}

h3 {
  text-align: center;
  font-weight: lighter;
}

.button-control {
  margin: auto;
  max-width: 50rem;
  text-align: center;
}

.centrali {
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

.resumo-compra {
  text-align: left;
  font-size: 25px;
  margin: 0px;
}

.resumo-compra > h3 {
  font-size: calc(1rem + 0.9vw);
  font-weight: 500;
  line-height: 1.2;
  margin: 40px 0px;
  color: #292929;
  text-align: left;
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
}

.form-cadastro,
.form-entrega {
  border: 1px solid black;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  max-width: 650px;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: auto;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

button:hover,
button:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

form {
  text-align: left;
  padding: 1rem;
  margin: auto;
  max-width: 80rem;
}

label {
  font-weight: bold;
  display: block;
  margin: 1rem 0;
}

#observation {
  font-weight: 500;
  margin: 0px 10px;
  padding: 1rem 0.75rem;
}

.form-floating {
  border-radius: 20px;
  max-width: 40rem;
  margin: 20px auto;
  font-size: 15px;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid select,
.invalid textarea {
  border: 1px solid red;
}

.fullbody {
  min-height: 100vh;
}

.margin {
  margin: 20px 0px;
}
</style>
