<template>
  <div
    id="carouselExampleDark"
    class="carousel carousel-dark slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <buttons-layout
        v-for="carroca in carrosselUrl"
        :key="carroca.slide_to"
        :slide_to="carroca.slide_to"
        :aria_label="carroca.aria_label"
      ></buttons-layout>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active col-md-6">
        <img
          :src="url"
          class="d-block mx-auto"
          style="width: 30rem; height: auto"
          alt="imagem produto"
        />
      </div>
      <img-layout
        v-for="carroca in carrosselUrl"
        :key="carroca.slide_to"
        :url_imagem="carroca.url_imagem"
      ></img-layout>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleDark"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleDark"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import buttonsLayout from "../imagemUrl/carrossel/buttonsLayout.vue";
import imgLayout from "../imagemUrl/carrossel/imgLayout.vue";

import { mapState } from "vuex";

export default {
  components: {
    buttonsLayout,
    imgLayout,
  },
  props: ["url"],
  computed: {
    ...mapState("produtos", ["carrosselUrl"]),
  },
  methods: {
    reender() {
      this.$forceUpdate
    }
  },
  created() {
    this.reender();
    this.carrosselUrl;
  },
};
</script>

<style scoped></style>
