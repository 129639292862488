export default {
    carregarDadosCliente(state, payload) {
        state.dadosCliente = payload;
    },
    resetCliente(state) {
        state.dadosCliente = null;
        state.dadosPedidos = null;
    },
    carregarMeusPedidos(state, payload) {
        state.dadosPedidos = payload;
    },
    usuarioLogado(state) {
        state.usuarioLogado = true;
    },
    usuarioDeslogado(state) {
        state.usuarioLogado = false;
    },
    carregamentoIniciado(state) {
        state.carregamentoPedidos = true;
    },
    carregamentoConcluido(state) {
        state.carregamentoPedidos = false;
    }

}