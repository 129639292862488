export default {
  async carregarRodape(context) {
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Empresa/Get?token=sensualle`
    );

    const responseData = await response.json();

    const empresa = {
      logradouro: responseData[0].logradouro,
      nome: responseData[0].nome_fantasia,
      numero: responseData[0].numero,
      cnpj: responseData[0].cnpj,
      bairro: responseData[0].bairro,
      cidade: responseData[0].nome_municipio,
      cep: responseData[0].cep,
      telefone: responseData[0].telefone,
      email: responseData[0].email,
      url_logo: responseData[0].url_logo,
    };

    document.documentElement.style.setProperty(
      "--primary-color",
      responseData[0].corcatalogo
    );

    context.commit("carregarRodape", empresa);
  },

  async carregarVendedores(context, payload) {
    const cnpj = payload.codigoempresa;
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Vendedor/Get/0?token=sensualle&cnpj=${cnpj}`
    );

    const responseData = await response.json();
    const vendedores = [];

    for (const key in responseData) {
      const vendedor = {
        nome: responseData[key].nome,
        codigo_vendedor: responseData[key].codigo_vendedor,
      };
      vendedores.push(vendedor);
    }
    context.commit("setarVendedores", vendedores);
  },

  //mostrar endereco de entrega ou nao
  async carregarGlobal(context) {
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Global/Get?pParametro=99101&token=sensualle`
    );

    const responseData = await response.json();

    context.commit("setarGlobal", responseData);
  },

  async permissaoUsuario(context) {
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Global/Get?pParametro=99345&token=sensualle`
    );

    const responseData = await response.json();

    context.commit("setarPermissaoUsuario", responseData);
  },
};
