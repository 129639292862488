function formatToUSADate(dateString) {
  const parts = dateString.split("-");
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

export default {
  async carregarCliente(context, payload) {
    const emailCliente = payload.email;
    const senhaCliente = payload.senha;
    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Cliente/Get/0?token=sensualle&pEmail=${emailCliente}`
    );

    const responseData = await response.json();

    if (responseData.length >= 1) {
      if (
        responseData[0].endereco.length === 0 &&
        responseData[0].senha == senhaCliente
      ) {
        const dadosCliente = {
          codigo: responseData[0].codigo,
          aprovado: responseData[0].aprovado,
          nome: responseData[0].nome,
          razao: responseData[0].razao,
          cnpj: responseData[0].cnpj,
          rg: "",
          cpf: responseData[0].cpf,
          senha: responseData[0].senha,
          telefone: responseData[0].telefone,
          celular: responseData[0].celular,
          contato: responseData[0].contato,
          IE: responseData[0].IE,
          email: responseData[0].email,
          site: "",
          observacao: "",
          foto: "",
          codigo_mobile: 1,
        };
        context.commit("carregarDadosCliente", dadosCliente);
        context.commit("usuarioLogado");
      } else if (
        responseData[0].endereco.length > 0 &&
        responseData[0].senha == senhaCliente
      ) {
        for (const yek in responseData[0].endereco) {
          const dadosCliente = {
            codigo: responseData[0].codigo,
            aprovado: responseData[0].aprovado,
            nome: responseData[0].nome,
            razao: responseData[0].razao,
            cnpj: responseData[0].cnpj,
            rg: "",
            cpf: responseData[0].cpf,
            senha: responseData[0].senha,
            telefone: responseData[0].telefone,
            celular: responseData[0].celular,
            contato: responseData[0].contato,
            IE: responseData[0].IE,
            email: responseData[0].email,
            site: "",
            observacao: "",
            foto: "",
            codigo_mobile: 1,
            endereco: [
              {
                codigo: responseData[0].endereco[yek].codigo,
                cidade: responseData[0].endereco[yek].cidade,
                logradouro: responseData[0].endereco[yek].logradouro,
                bairro: responseData[0].endereco[yek].bairro,
                numero: responseData[0].endereco[yek].numero,
                complemento: responseData[0].endereco[yek].complemento,
                cep: responseData[0].endereco[yek].cep,
                uf: responseData[0].endereco[yek].uf,
              },
            ],
          };
          context.commit("carregarDadosCliente", dadosCliente);
          context.commit("usuarioLogado");
        }
      } else {
        window.alert("Wrong password! Try again.");
        const dadosCliente = null;
        context.commit("carregarDadosCliente", dadosCliente);
      }
    } else {
      window.alert("The user was not found, would you like to register?");
      const dadosCliente = null;
      context.commit("carregarDadosCliente", dadosCliente);
    }
  },

  resetCliente(context) {
    context.commit("resetCliente");
    context.commit("usuarioDeslogado");
  },

  async carregarMeusPedidos(context, payload) {
    context.commit("carregamentoIniciado");
    const emailCliente = payload.email;
    const senhaCliente = payload.senha;
    const cnpjEmpresa = payload.cnpj;
    const dataInicio = payload.dataInicio + " 00:00:00";
    const dataFim = payload.dataFim + " 23:59:59";

    const response = await fetch(
      `https://apiciriuserp.com.br:2083/api/Cliente/Get/0?token=sensualle&pEmail=${emailCliente}`
    );

    const responseData = await response.json();

    try {
      const codigo = responseData[0].codigo;

      const response2 = await fetch(
        `https://apiciriuserp.com.br:2083/api/Venda/Get/0?token=sensualle&cnpj=${cnpjEmpresa}&de=${dataInicio}&ate=${dataFim}&cliente=${codigo}
        `
      );

      const response2Data = await response2.json();
      const meusPedidos = [];

      if (response2Data.length >= 1 && senhaCliente == responseData[0].senha) {
        for (const key in response2Data) {
          const meuPedido = {
            origem: response2Data[key].origem,
            codigoVenda: response2Data[key].codigo,
            empresa: response2Data[key].empresa,
            dataInclusao: formatToUSADate(
              response2Data[key].data_inclusao.substr(0, 10)
            ),

            previsao: formatToUSADate(
              response2Data[key].previsao_entrega.substr(0, 10)
            ),
            valorTotal: response2Data[key].valorLiquido,
            notafiscal: response2Data[key].notafiscal,
            ChaveEletronicaNFeSaida: response2Data[key].ChaveEletronicaNFeSaida,
            descricao: response2Data[key].descricao_status,
            Rastreamento: response2Data[key].rastreamento,
            itens: [],
          };
          meusPedidos.push(meuPedido);
        }
        for (const key in response2Data) {
          for (const yek in response2Data[key].itens) {
            const meuPedidoItens = {
              ref: response2Data[key].itens[yek].ref_produto,
              nomeproduto: response2Data[key].itens[yek].nome_produto,
              descSku: response2Data[key].itens[yek].descricao_sku,
              preco: response2Data[key].itens[yek].preco,
              quantidade: response2Data[key].itens[yek].quantidade,
              precoTotal: response2Data[key].itens[yek].precototal,
              url: response2Data[key].itens[yek].URL,
            };
            meusPedidos[key].itens.push(meuPedidoItens);
          }
        }
        context.commit("carregarMeusPedidos", meusPedidos);
        context.commit("carregamentoConcluido");
      } else {
        context.commit("carregarMeusPedidos", null);
        context.commit("carregamentoConcluido");
      }
    } catch (error) {
      console.log(error);
    }
  },
};
