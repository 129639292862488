<template>
  <div class="container">
    <h1>Search results for : "{{ this.busca }}"</h1>
    <hr />
  </div>
  <img
    v-if="topovisibility"
    @click="top"
    id="back-to-top"
    src="../../public/totop.png"
    alt="voltar ao topo botão"
  />
  <div>
    <div class="container">
      <div v-if="carregamento" style="margin-bottom: 50px">
        <base-spinner></base-spinner>
      </div>
      <p id="naoencontrado" v-if="termoBuscado.length == 0 && !carregamento">
        We didn't find any product for: "{{ this.busca }}", please try again.
      </p>
      <ul v-if="termoBuscado.length > 0 && !carregamento" style="padding: 0">
        <div class="row">
          <item-produto
          class="col-md col-6 p-1"
            v-for="produto in termoBuscado"
            :key="produto.nomeproduto"
            :nomeproduto="produto.nomeproduto"
            :url="produto.url"
            :detalhes="produto.detalhes"
            :preco="produto.preco"
            :saldo="produto.saldo"
            :codigo="produto.codigo"
            :referencia="produto.referencia"
          >
          </item-produto>
        </div>
      </ul>
    </div>
  </div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import ItemProduto from "../components/produtos/ItemProduto.vue";
import { mapState } from "vuex";
import router from "@/router";

export default {
  components: {
    ItemProduto,
  },
  data() {
    return {
      isLoading: false,
      topovisibility: false,
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("produtos", ["termoBuscado"]),
    ...mapState("produtos", ["categoria"]),
    ...mapState("produtos", ["carregamento"]),

    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    busca() {
      return this.$store.getters["loja/buscaPesquisada"];
    },
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.carregarRodape();
    this.carregarCategorias();
  },
  mounted() {
    if (
      router.currentRoute.value.fullPath ==
      "/" + "products/search/" + this.busca
    ) {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 1145) {
          this.topovisibility = true;
        } else {
          this.topovisibility = false;
        }
      });
    }
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
h1 {
  color: var(--primary-color);
  margin: 40px;
}

#back-to-top {
  position: fixed;
  width: 80px;
  bottom: 0%;
  right: 0%;
  cursor: pointer;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

ul {
  list-style: none;
  margin: 2rem auto;
}

div {
  margin: 0;
  padding: 0;
  margin: auto;
  max-width: 85rem;
}

img {
  margin-left: 1rem;
}

input {
  margin: 0;
  margin: auto;
  width: 90%;
}

#naoencontrado {
  margin-bottom: 100px;
  text-align: center;
  font-size: 20px;
}
</style>
