<template>
  <li class="product mb-3">
    <div class="product__data">
      <div class="container p-0" style="height: 100%;">
        <div class="row justify-content-center align-items-center" style="height: 100%;">
          <div class="col-12">
            <div class="product__image">
              <router-link
                :to="produtoPageLink"
                v-slot="{ href, route, navigate }"
              >
                <button id="imgbutton" :href="href" @click="navigate">
                  <img :src="url" alt="imagem produto" />
                  {{ route.ProdutoPage }}
                </button>
              </router-link>
            </div>
          </div>
          <div style="margin: 10px" class="col">
            <h3>{{ referencia }} - {{ nomeproduto }}</h3>
          </div>
          <div class="row d-flex justify-content-center flex-nowrap">
            <div v-if="this.permissaoUsuarioPage">
              <div class="row">
                <div class="mb-3" v-if="saldo != 999">
                  <h4>Estoque: {{ saldo }}</h4>
                </div>
                <div>
                  <h2>
                    <strong>${{ precoindividual }} USD</strong>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="col align-self-end">
            <div class="product__actions">
              <router-link
                :to="produtoPageLink"
                v-slot="{ href, route, navigate }"
              >
                <button :href="href" @click="navigate">
                  Show details {{ route.ProdutoPage }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["url", "nomeproduto", "referencia", "saldo", "codigo", "preco"],

  data() {
    return {};
  },
  computed: {
    ...mapState("empresa", ["permissaoUsuario"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("login", ["usuarioLogado"]),
    somaprecototal() {
      return (this.qtditem * this.preco).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    precoindividual() {
      return this.preco.toLocaleString("pt-br", { minimumFractionDigits: 2 });
    },
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    produtoPageLink() {
      return "/products/" + this.codigo;
    },
    permissaoUsuarioPage() {
      if (this.permissaoUsuario == "2") {
        return true;
      } else if (this.permissaoUsuario == "1" && this.usuarioLogado == true) {
        if (this.dadosCliente.aprovado == "S") {
          return true;
        }
        return false;
      } else return false;
    },
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
    carregarPermissaoUsuario() {
      this.$store.dispatch("empresa/permissaoUsuario");
    },
  },
  created() {
    this.carregarPermissaoUsuario();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

h4 {
  display: flex;
  flex-wrap: nowrap;
  font-weight: 350;
  font-size: 15px;
  margin: auto;
}

h2 {
  color: var(--primary-color);
  font-size: 1.3rem;
}

#marginzero {
  margin: 0px;
  display: inline;
}

#imgbutton {
  background-color: #31b19a00;
  border: none;
  padding: 0;
  margin: 0;
}

li {
  display: inline;
  margin: 0px;
}

.product__data {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  height: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .product__data {
    width: auto;
    padding: 1rem;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) {
  .product__data {
    width: 18rem;
    padding: 1rem;
    margin: 2rem auto;
  }
}

.data__qtd {
  height: 0px;
  display: inherit;
  margin-top: 15px;
}

select {
  height: 40px;
  width: 60px;
  font-size: 20px;
}

p.quantity {
  font-size: 20px;
  margin-right: 5px;
}

.product__image {
  margin: auto;
  display: block;
}

.product__image img {
  height: auto;
  margin-top: 15px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .product__image img {
    width: 8rem;
  }
}

@media (min-width: 769px) {
  .product__image img {
    width: 15rem;
  }
}

.product__data h3 {
  margin: 0 0 0.5rem 0;
}

@media (max-width: 768px) {
  .product__data h3 {
    font-size: 1rem;
  }
}

@media (min-width: 769px) {
  .product__data h3 {
    font-size: 1.25rem;
  }
}

.product__data h4 {
  margin: 0;
  font-size: auto - 20%;
  display: contents;
}

.product__actions {
  text-align: center;
  margin: 0;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

@media (max-width: 768px) {
  button {
    margin: 0rem 0rem;
  }
}

@media (min-width: 769px) {
  button {
    margin: 1rem 1rem;
  }
}

button:hover,
button:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.itens {
  font-size: 20px;
  text-align: center;
}

.itensdesc {
  font-size: 20px;
  font-weight: lighter;
  text-align: left;
}

.itensselec {
  padding: 40px;
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
}

.itenstotal {
  font-size: 30px;
  color: black;
  text-align: left;
}
</style>
