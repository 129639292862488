<template>
  <the-header></the-header>
  <router-view></router-view>
</template>

<script>
import TheHeader from "./components/nav/TheHeader.vue";
import { mapState } from "vuex";

export default {
  data() {},
  components: {
    TheHeader,
  },
  computed: {
        ...mapState("empresa", ["empresarodape"]),
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
    document.title = this.empresarodape.nome;
    },
  },
  created() {
    this.carregarRodape();
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}
</style>
