export default {
  adicionarProdutoCarrinho(state, payload) {
    const produtoData = payload;
    const variacaoData = state.userSelect;

    var init = 0;
    var precotot = 0;

    for (const key in variacaoData) {
      if (state.itens.length === 0 && variacaoData[key].qtd > 0) {
        const newItem = {
          nomeproduto: produtoData.nomeproduto,
          codigo: produtoData.codigo,
          url: produtoData.url,
          preco: produtoData.preco,
          qtd: variacaoData[key].qtd,
          variacao: [],
        };
        const newVar = {
          codigo_sku: variacaoData[key].codigo_sku,
          codigo_cor: variacaoData[key].codigo_cor,
          quantidade: variacaoData[key].qtd,
          preco: variacaoData[key].preco,
          tamanho: variacaoData[key].tamanho,
          descricao_sku: variacaoData[key].descricao_sku,
          url: variacaoData[key].url,
        };
        init += variacaoData[key].qtd;
        precotot += variacaoData[key].qtd * variacaoData[key].preco;
        newItem["variacao"].push(newVar);
        state.itens.push(newItem);
      } else {
        for (const yek in state.itens) {
          const corNoCarrinhoIndex = state.itens[yek]["variacao"].findIndex(
            (ci) => ci.codigo_cor === variacaoData[key].codigo_cor
          );
          const variacaoNoCarrinhoIndex = state.itens[yek][
            "variacao"
          ].findIndex((ci) => ci.codigo_sku === variacaoData[key].codigo_sku);

          const produtoNoCarrinhoIndex = state.itens.findIndex(
            (ic) => ic.codigo === variacaoData[key].codigo_prod
          );

          if (
            corNoCarrinhoIndex >= 0 &&
            variacaoData[key].qtd > 0 &&
            variacaoNoCarrinhoIndex < 0 &&
            produtoNoCarrinhoIndex >= 0 &&
            variacaoData[key].codigo_prod === state.itens[yek].codigo
          ) {
            //console.log('if')
            state.itens[yek].qtd += variacaoData[key].qtd;
            const newVar = {
              codigo_sku: variacaoData[key].codigo_sku,
              codigo_cor: variacaoData[key].codigo_cor,
              quantidade: variacaoData[key].qtd,
              preco: variacaoData[key].preco,
              tamanho: variacaoData[key].tamanho,
              descricao_sku: variacaoData[key].descricao_sku,
              url: variacaoData[key].url,
            };
            init += variacaoData[key].qtd;
            precotot += variacaoData[key].qtd * variacaoData[key].preco;
            state.itens[yek].variacao.push(newVar);
            break;
          } else if (
            corNoCarrinhoIndex >= 0 &&
            variacaoData[key].qtd > 0 &&
            variacaoNoCarrinhoIndex >= 0 &&
            produtoNoCarrinhoIndex >= 0
          ) {
            //console.log("ielse if 1");
            init += variacaoData[key].qtd;
            precotot += variacaoData[key].qtd * variacaoData[key].preco;
            state.itens[yek].qtd += variacaoData[key].qtd;
            state.itens[yek].variacao[variacaoNoCarrinhoIndex].quantidade +=
              variacaoData[key].qtd;
            break;
          } else if (
            corNoCarrinhoIndex < 0 &&
            variacaoData[key].qtd > 0 &&
            variacaoNoCarrinhoIndex < 0 &&
            produtoNoCarrinhoIndex >= 0 &&
            state.itens.length == parseInt(yek) + 1
          ) {
            //console.log("ielse if 2");
            const newItem = {
              nomeproduto: produtoData.nomeproduto,
              codigo: produtoData.codigo,
              url: produtoData.url,
              preco: produtoData.preco,
              qtd: variacaoData[key].qtd,
              variacao: [],
            };
            const newVar = {
              codigo_sku: variacaoData[key].codigo_sku,
              codigo_cor: variacaoData[key].codigo_cor,
              quantidade: variacaoData[key].qtd,
              preco: variacaoData[key].preco,
              tamanho: variacaoData[key].tamanho,
              descricao_sku: variacaoData[key].descricao_sku,
              url: variacaoData[key].url,
            };
            init += variacaoData[key].qtd;
            precotot += variacaoData[key].qtd * variacaoData[key].preco;
            newItem["variacao"].push(newVar);
            state.itens.push(newItem);
            break;
          } else if (produtoNoCarrinhoIndex < 0 && variacaoData[key].qtd > 0) {
            //console.log("ielse if 3");

            const newItem = {
              nomeproduto: produtoData.nomeproduto,
              codigo: produtoData.codigo,
              url: produtoData.url,
              preco: produtoData.preco,
              qtd: variacaoData[key].qtd,
              variacao: [],
            };
            const newVar = {
              codigo_sku: variacaoData[key].codigo_sku,
              codigo_cor: variacaoData[key].codigo_cor,
              quantidade: variacaoData[key].qtd,
              preco: variacaoData[key].preco,
              tamanho: variacaoData[key].tamanho,
              descricao_sku: variacaoData[key].descricao_sku,
              url: variacaoData[key].url,
            };
            init += variacaoData[key].qtd;
            precotot += variacaoData[key].qtd * variacaoData[key].preco;
            newItem["variacao"].push(newVar);
            state.itens.push(newItem);
            break;
          }
        }
      }
    }

    state.qtd += init;
    state.total += precotot;
  },
  removerProdutoCarrinho(state, payload) {
    const produtoDataIndex = payload;
    const prodData = state.itens[produtoDataIndex];
    for(const key in prodData.variacao) {
      state.total -= (prodData.variacao[key].quantidade * prodData.variacao[key].preco);
    }
    
    state.itens.splice(produtoDataIndex, 1);
    state.qtd -= prodData.qtd;
  },
  carregarCalculados(state, payload) {
    state.userSelect = payload;
  },
  resetSelect(state) {
    state.userSelect = [];
    state.itens = [];
    state.qtd = 0;
    state.total = 0;
  },

  verificacaoQuantidade(state, payload) {
    state.disponibilidade = payload;
  },
};
