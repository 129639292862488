<template>
  <div class="espace">
    <form @submit.prevent="submitForm">
      <div class="login">
        <h2>Login</h2>
        <div class="mb-3">
          <br />
          <input
            type="radio"
            id="cpf"
            name="cpfOucnpj"
            value="cpf"
            v-model="cpfOucnpj.val"
          />
          <label style="display: inline; margin: 10px 10px" for="cpf"
            >Pessoa Física</label
          >
          <input
            type="radio"
            id="cnpj"
            name="cpfOucnpj"
            value="cnpj"
            v-model="cpfOucnpj.val"
          />
          <label style="display: inline; margin: 10px 10px" for="cnpj"
            >Pessoa Jurídica</label
          >
          <div
            class="form-control"
            :class="{ invalid: !cadastroPessoaJuridica.isValid }"
            v-if="cpfOucnpj.val === 'cnpj'"
          >
            <label for="cadastroPessoaJuridica">CNPJ:</label>
            <input
              type="text"
              id="cadastroPessoaJuridica"
              v-model.trim="cadastroPessoaJuridica.val"
              style="width: 40%"
              v-maska="'##.###.###/####-##'"
              @blur="clearValidity('cadastroPessoaJuridica')"
            />
            <p v-if="!cadastroPessoaJuridica.isValid">CNPJ inválido.</p>
          </div>
          <div
            class="form-control"
            :class="{ invalid: !cadastroPessoaFisica.isValid }"
            v-if="cpfOucnpj.val === 'cpf'"
          >
            <label for="cadastroPessoaFisica">CPF:</label>
            <input
              type="text"
              id="cadastroPessoaFisica"
              v-model.trim="cadastroPessoaFisica.val"
              style="width: 40%"
              v-maska="'###.###.###-##'"
              @blur="clearValidity('cadastroPessoaFisica')"
            />
            <p v-if="!cadastroPessoaFisica.isValid">CPF inválido.</p>
          </div>
          <div class="mb-3 col-md-6" :class="{ invalid: !senha.isValid }">
            <label for="senha" class="form-label">Senha:</label>
            <input
              type="password"
              id="senha"
              v-model.trim="senha.val"
              @blur="clearValidity('senha')"
            />
          </div>
          <p v-if="!senha.isValid">Senha inválida.</p>
          <div class="form-text">
            Se nunca tiver feito um pedido antes, basta clicar em novo cadastro.
          </div>
        </div>
        <div v-if="isLoading"><base-spinner></base-spinner><br /></div>
        <div v-if="isEntered">
          <div class="button-control">
            <button @click="validateForm">Entrar</button>
          </div>
          <router-link :to="formLink" v-slot="{ href, route, navigate }">
            <button
              :href="href"
              @click="
                resetCliente();
                navigate;
              "
            >
              Novo Cadastro?{{ route.ClienteForm }}
            </button></router-link
          >
        </div>
      </div>
    </form>
  </div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      isEntered: true,
      cadastroPessoaFisica: {
        val: "",
        isValid: true,
      },
      cadastroPessoaJuridica: {
        val: "",
        isValid: true,
      },
      cpfOucnpj: {
        val: "cpf",
        isValid: true,
      },
      senha: {
        val: "",
        isValid: true,
      },
    };
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    validateForm() {
      this.formIsValid = true;
      if (this.cpfOucnpj.val === "cpf") {
        if (
          this.cadastroPessoaFisica.val == "" ||
          this.cadastroPessoaFisica.val.length < 14
        ) {
          this.cadastroPessoaFisica.isValid = false;
          this.formIsValid = false;
        }
      } else {
        if (
          this.cadastroPessoaJuridica.val == "" ||
          this.cadastroPessoaJuridica.val.length < 18
        ) {
          this.cadastroPessoaJuridica.isValid = false;
          this.formIsValid = false;
        }
      }
      if (
        this.senha.val.length < 4 ||
        this.senha.val.length > 20 ||
        this.senha.val == ""
      ) {
        this.senha.isValid = false;
        this.formIsValid = false;
      }
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    resetCliente() {
      this.$store.dispatch("login/resetCliente");
    },
    async submitForm() {
      if (this.formIsValid == true) {
        this.isEntered = false;
        this.isLoading = true;
        if (this.cpfOucnpj.val === "cpf") {
          await this.$store.dispatch("login/carregarCliente", {
            codigo: this.cadastroPessoaFisica.val,
            senha: this.senha.val,
          });
        } else {
          await this.$store.dispatch("login/carregarCliente", {
            codigo: this.cadastroPessoaJuridica.val,
            senha: this.senha.val,
          });
        }

        this.isLoading = false;
        this.$router.push(this.formLink);
      }
    },
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("login", ["dadosCliente"]),

    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    formLink() {
      return "/checkout";
    },
  },
  created() {
    this.carregarRodape();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.espace {
  margin-top: 20px;
  margin-bottom: 50px;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.form-control,
.button-control {
  display: inline;
  padding: 0px;
  margin: auto;
  max-width: 50rem;
  border-radius: 10px;
  border: 0px;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: 0px 20px 0px 0px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

.login {
  border: 1px solid black;
  padding: 20px;
  border-radius: 10px;
}

h2 {
  text-align: center;
  background: var(--primary-color);
  color: white;
  padding: 10px;
  border: 1px solid black;
  margin: -21px -21px 0px -21px;
  border-radius: 10px 10px 0px 0px;
}

form {
  text-align: left;
  padding: 1rem;
  margin: auto;
  max-width: 35rem;
}

label {
  font-weight: bold;
  display: block;
  margin: 1rem 0;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid select,
.invalid textarea {
  border: 1px solid red;
}
</style>
