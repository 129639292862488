export default {
    empresarodape(state) {
        return state.empresarodape;
    },
    vendedores(state) {
        return state.vendedores;
    },
    global(state) {
        return state.global;
    },
    permissaoUsuario(state) {
        return state.permissaoUsuario;
    }
}