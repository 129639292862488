<template>
    <div class="text-center">
      <v-menu open-on-hover>
        <template v-slot:activator="{ props }">
          <router-link :to="categoriaLink" v-slot="{ href, route, navigate }">
            <v-btn
              color="white"
              variant="text"
              v-bind="props"
              :href="href"
              @click="navigate()"
            >
              {{ item }}{{ route.CategoriaPage }}
              <span v-if="filhas.length > 0"><v-icon right>mdi-arrow-down</v-icon></span>
            </v-btn>
          </router-link>
        </template>
        <v-list v-if="filhas.length > 0" max-height="450">
          <v-list-item v-for="(item, index) in filhas" :key="index">
            <v-list-item-title>
              <router-link :to="categoriaLinkFilha + item.codigo" v-slot="{ href, route, navigate }">
              <v-btn
                color="black"
                variant="text"
                :href="href"
                @click="navigate()"
              >
                {{ item.nome }}{{ route.CategoriaPage }}
              </v-btn>
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </template>
  
  <script>
  export default {
    props: ["item", "codigo", "filhas"],
    components: {},
    data() {
      return {};
    },
    computed: {
      loja() {
        return this.$store.getters["loja/lojaSelecionada"];
      },
      tipo() {
        return this.$store.getters["loja/tipoSelecionado"];
      },
      categoriaLink() {
        return (
          "/products/" +
          "categories/" +
          this.codigo
        );
      },
      categoriaLinkFilha() {
        return "/products/" + "categories/";
      },
    },
    methods: {},
    created() {},
  };
  </script>
  
  <style scoped></style>
  