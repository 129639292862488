<template>
  <div id="interface">
    <section>
      <h1>My Cart</h1>
      <hr />
      <div id="carrinho" v-if="carrinhoItens.length === 0" class="fullbody">
        <p>Your cart is empty...</p>
        <router-link :to="produtosLink" v-slot="{ href, route, navigate }">
          <button :href="href" @click="navigate">
            Go back to products{{ route.ListaProdutos }}
          </button></router-link
        >
      </div>
      <div id="produto" v-else>
        <div class="container">
          <div class="row">
            <div class="col-md">
              <ul>
                <item-carrinho
                  v-for="item in carrinhoItens"
                  :key="item.codigo"
                  :item="item"
                >
                </item-carrinho>
              </ul>
            </div>
            <div class="col-sm-4">
              <div id="resumodacompra">
                <h2>Review Your Order</h2>
                <p><strong>Total:</strong> ${{ carrinhoTotal }} USD</p>
                <p><strong>Quantity:</strong> {{ quantidadeTotal }}</p>
                <div class="button-control">
                  <button @click="validateqtd">Checkout</button>
                </div>
                <div v-if="isLoading">
                  <base-spinner></base-spinner>
                  <br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

import ItemCarrinho from "../components/carrinho/ItemCarrinho.vue";

export default {
  components: {
    ItemCarrinho,
  },
  data() {
    return {
      isQtdValid: true,
      isLoading: false,
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("carrinhos", ["itens"]),
    ...mapState("carrinhos", ["disponibilidade"]),
    ...mapState("login", ["usuarioLogado"]),

    carrinhoTotal() {
      return this.$store.getters["carrinhos/somaTotal"].toLocaleString(
        "pt-br",
        { minimumFractionDigits: 2 }
      );
    },
    quantidadeTotal() {
      return this.$store.getters["carrinhos/quantidade"];
    },
    carrinhoItens() {
      return this.$store.getters["carrinhos/produtos"];
    },
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    resumoLink() {
      return "/checkout";
    },
    produtosLink() {
      return "/products";
    },
    minhaConta() {
      return "/products/myaccount";
    },
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    async validateqtd() {
      this.isLoading = true;
      if (this.usuarioLogado == true) {
        await this.$store.dispatch("carrinhos/verificacao");
        if (this.disponibilidade === "disponivel") {
          this.$router.push(this.resumoLink);
        } else {
          window.alert("Não temos em estoque a quantidade selecionada.");
          this.isQtdValid = false;
        }
      } else {
        window.alert("Please log in to continue.");
        this.$router.push(this.minhaConta);
      }

      this.isLoading = false;
    },
  },
  created() {
    this.carregarRodape();
    this.carregarCategorias();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

section {
  margin: 2rem auto;
  max-width: 90rem;
}

h1 {
  color: var(--primary-color);
  margin: 0px 40px;
}

h3 {
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0px;
}

.fullbody {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.25rem;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: 1rem auto;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
}

button:hover,
button:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

div#carrinho {
  text-align: center;
}

div#carrinho > p {
  text-align: center;
  font-size: 1.5rem;
}

#resumodacompra {
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  text-align: center;
  max-width: 45rem;
  margin-bottom: 8px;
  margin-top: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

#resumodacompra > p {
  text-align: center;
  margin-left: auto;
  font-size: 15px;
  margin: 0px;
}
#resumodacompra > h2 {
  text-align: center;
  margin-left: auto;
  font-size: 20px;
}

.button-control {
  text-align: center;
  margin-left: auto;
  font-size: 15px;
}

div#interface {
  max-width: 80rem;
  margin: auto;
}

div#produto {
  margin: 20px;
}
</style>
