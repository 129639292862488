export default {
  carregarProdutos(state, payload) {
    state.produtos = [...state.produtos, ...payload];
  },

  carregarSimilar(state, payload) {
    state.produtoSimilar = payload;
  },

  carregarProdutoDado(state,payload) {
    state.produtoDado = payload;
  },

  carregarCategoriaSelecionada(state,payload) {
    state.categoriaDada = payload;
  },

  carregarBuscaBuscada(state,payload) {
    state.termoBuscado = payload;
  },

  carregarBuscaVazia(state) {
    state.termoBuscado = [];
  },

  carregamento(state, payload) {
    state.carregamento = payload;
  },

  carregarVariacoes(state, payload) {
    state.coresTamanhos = payload;
  },

  resetVariacoes(state) {
    state.coresTamanhos = [];
  },
  
  resetProdutos(state) {
    state.produtos = [];
  },

  acabouprodutos(state, payload) {
    state.acabouprodutos = payload;
  },

  carregarCalculados(state, payload) {
    state.userSelect = payload;
  },

  resetSelect(state) {
    state.userSelect = [];
  },

  carregarCarrossel(state,payload) {
    state.carrosselUrl = payload;
  },
  carregarCarrosselHeader(state,payload) {
    state.carrosselUrlHeader = payload;
  },

  resetcarregarCarrossel(state) {
    state.carrosselUrl = null;
  },
  resetcarregarCarrosselHeader(state) {
    state.carrosselUrlHeader = null;
  },

  carregarCategoria(state, payload) {
    state.categoria = payload;
  },

  isCarregado(state) {
    state.isCarregado = true
  },

  isNotCarregado(state) {
    state.isCarregado = false
  }

};
