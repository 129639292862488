<template>
  <button
    type="button"
    data-bs-target="#carouselExampleDark"
    :data-bs-slide-to="slide_to"
    :aria-label="aria_label"
  ></button>
</template>

<script>
export default {
  props: ["slide_to", "aria_label"],
};
</script>

<style></style>
