export default {
    carregarLoja(state, payload) {
        state.lojaSelecionada = payload.lojaSelecionada;
    },
    paginacao(state) {
        state.pagina++;
      },
      resetPaginacao(state) {
        state.pagina = 0;
      }
}