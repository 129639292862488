<template>
  <teleport to="body">
    <div>
      <div v-if="open" @click="$emit('close')" id="estilo"></div>
      <transition name="modal">
        <dialog open v-if="open">
          <header>
            <slot name="header">
              <h2>{{ title }}</h2>
            </slot>
          </header>
          <section>
            <slot></slot>
          </section>
          <menu>
            <slot name="actions"> </slot>
          </menu>
        </dialog>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  props: ["title", "open"],
  emits: ["close"],
};
</script>

<style scoped>
@import "@/styles/variables.css";

div#estilo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  width: 100%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 2px;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

section {
  text-align: center;
  padding: 1rem;
}

header {
  background-color: var(--primary-color);
  color: white;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

menu {
  padding: 1rem;
  display: block;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(40% - 20rem);
    width: 60rem;
  }
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>
