export default {
  adicionarCarrinho(context) {
    const produtos = context.rootGetters["produtos/produtoDado"];
    context.commit("adicionarProdutoCarrinho", produtos);
  },
  removerDoCarrinho(context, payload) {
    const prodCod = payload.codigo;
    const produtos = context.rootGetters["carrinhos/produtos"];
    const produtoNoCarrinhoIndex = produtos.findIndex(
      (carrinhoItem) => carrinhoItem.codigo === prodCod
    );

    context.commit("removerProdutoCarrinho", produtoNoCarrinhoIndex);
  },
  produtosCalculados(context, payload) {
    context.commit("carregarCalculados", payload.produtoSelecionado);
  },
  resetSelect(context) {
    context.commit("resetSelect");
  },
  async verificacao(context) {
    const quantidadeCarrinho = context.rootGetters["carrinhos/produtos"];

    const responseVeri = await fetch(
      `https://apiciriuserp.com.br:2083/api/Global/Get?pParametro=99200&token=sensualle`
    );

    const responseVeriData = await responseVeri.json();

    if (responseVeriData == 1) {
      for (const key in quantidadeCarrinho) {
        const codigo = quantidadeCarrinho[key].codigo;

        const response = await fetch(
          `https://apiciriuserp.com.br:2083/api/SKUCatalogo/Get?token=sensualle&pProduto=${codigo}`
        );

        const responseData = await response.json();

        for (const yek in quantidadeCarrinho[key]["variacao"]) {
          const codigoSkuIndex = responseData.findIndex(
            (SkuIndex) =>
              parseInt(SkuIndex.codigo_sku) ===
              parseInt(quantidadeCarrinho[key]["variacao"][yek].codigo_sku)
          );

          if (
            parseInt(quantidadeCarrinho[key]["variacao"][yek].quantidade) >
            parseInt(responseData[codigoSkuIndex].saldo)
          ) {
            context.commit("verificacaoQuantidade", "indisponivel");
            return;
          } else {
            context.commit("verificacaoQuantidade", "disponivel");
          }
        }
      }
    } else if (responseVeriData == 2) {
      context.commit("verificacaoQuantidade", "disponivel");
    }
  },
};
