<template>
  <li class="product">
    <div class="product__data">
      <div class="container" style="height: 100%;">
        <div class="row justify-content-center align-items-center" style="height: 100%;">
          <div class="col-12">
            <div class="product__image">
              <button id="imgbutton" @click="reender()">
                <img :src="url" alt="imagem produto" />
              </button>
            </div>
          </div>
          <div style="margin: 10px" class="col">
            <h3>{{ referencia }} - {{ nomeproduto }}</h3>
          </div>
          <div
            class="row d-flex justify-content-center flex-nowrap"
            v-if="this.permissaoUsuarioPage"
          >
            <div class="col">
              <h4>
                <strong>${{ precoindividual }} USD</strong>
              </h4>
            </div>
            <div class="col" v-if="saldo != 999">
              <h4>
                Estoque:<strong>{{ saldo }}</strong>
              </h4>
            </div>
          </div>
          <div class="col align-self-end">
            <div class="product__actions">
              <button @click="reender()">View details</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["url", "nomeproduto", "referencia", "saldo", "codigo", "preco"],

  data() {
    return {};
  },
  computed: {
    ...mapState("empresa", ["permissaoUsuario"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("login", ["usuarioLogado"]),
    somaprecototal() {
      return (this.qtditem * this.preco).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
    precoindividual() {
      return this.preco.toLocaleString("pt-br", { minimumFractionDigits: 2 });
    },
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    produtoPageLink() {
      return "/products/" + this.codigo;
    },
    permissaoUsuarioPage() {
      if (this.permissaoUsuario == "2") {
        return true;
      } else if (this.permissaoUsuario == "1" && this.usuarioLogado == true) {
        if (this.dadosCliente.aprovado == "S") {
          return true;
        }
        return false;
      } else return false;
    },
  },
  methods: {
    async reender() {
      await this.$router.push(this.produtoPageLink);
      this.$router.go();
    },
    carregarPermissaoUsuario() {
      this.$store.dispatch("empresa/permissaoUsuario");
    },
  },
  created() {
    this.carregarPermissaoUsuario();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

h4 {
  display: flex;
  flex-wrap: nowrap;
  font-weight: 350;
  font-size: 15px;
  margin: auto;
}

#marginzero {
  margin: 0px;
  display: inline;
}

#imgbutton {
  background-color: #31b19a00;
  border: none;
  padding: 0;
  margin: 0;
}

li {
  display: inline;
  margin: auto;
}

.product__data {
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 2rem auto;
  text-align: center;
  height: 90%;
  max-width: 18rem;
}

.product__image {
  margin: auto;
  display: block;
}

.product__image img {
  height: auto;
  width: 10rem;
  margin-top: 15px;
  object-fit: cover;
}

.product__data h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
}

.product__data h4 {
  margin: 0;
  font-size: auto - 20%;
  display: contents;
}

.product__actions {
  text-align: center;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: 1rem 1rem;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
  font-size: 13px;
}

button:hover,
button:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>
