<template>
  <nav>
    <div class="navbar navbar-light bg-white">
      <div class="container">

        <v-layout v-if="celular">
          <v-app-bar-nav-icon
            variant="text"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>

          <v-navigation-drawer
            v-model="drawer"
            :width="400"
            temporary
          >
            <item-categoria-novo-celular
              v-for="item in categoria"
              :key="item.codigoCategoria"
              :codigo="item.codigoCategoria"
              :item="item.categoria"
              :filhas="item.filhas"
            >
            </item-categoria-novo-celular>
          </v-navigation-drawer>
        </v-layout>

        <!-- logo -->
        <div class="d-flex align-items-center">
          <router-link :to="produtosLink"
            ><img :src="empresarodape.url_logo" alt="logo" width="200"
          /></router-link>
        </div>

        <!-- pesquisa -->
        <form class="d-flex align-items-center" role="search">
          <input
            type="search"
            v-model.trim="buscador"
            style="margin-right: 10px"
            class="form-control search_bar"
            placeholder="Search products..."
            aria-label="Search"
          />

          <router-link
            :to="buscaLink"
            v-slot="{ href, route, navigate }"
            :class="{ disabled: this.buscador == '' }"
          >
            <button
              :href="href"
              @click="
                carregarBusca();
                navigate();
              "
              class="btn btn-outline-success"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                ></path>
              </svg>
              {{ route.BuscaPage }}
            </button>
          </router-link>
        </form>


        <div class="d-flex align-items-center">
          
          <!-- barcode -->
          <router-link :to="barcodeLink">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="32"
              fill="currentColor"
              class="bi bi-upc-scan"
              viewBox="0 0 16 16"
            >
              <path
                d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
              />
            </svg>
          </router-link>
          
          <!-- carrinho -->
          <router-link :to="carrinhosLink"
            ><img
              src="../../../public/shopping-cart.png"
              alt="logo"
              width="30"
            />
            <base-badge mode="elegant"
              >{{ carrinhoQuantidade }}
            </base-badge></router-link
          >
          <!-- login -->
          <router-link
            :to="minhaConta"
            id="meuspedidos"
            v-if="this.usuarioLogado == true"
            class="d-flex flex-nowrap align-items-center"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-person-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
              />
            </svg>
            <p>Hello, {{ nomeBoasVindas }}!</p>
          </router-link>
          <router-link
            :to="minhaConta"
            id="meuspedidos"
            v-if="this.usuarioLogado == false"
            ><button>Sign in</button>
          </router-link>
        </div>
      </div>
    </div>
    <v-row
      v-if="!celular"
      class="justify-space-around"
      style="background-color: #232f3f; padding: 20px"
    >
      <item-categoria-novo
        v-for="item in categoria"
        :key="item.codigoCategoria"
        :codigo="item.codigoCategoria"
        :item="item.categoria"
        :filhas="item.filhas"
      >
      </item-categoria-novo>
    </v-row>
  </nav>
</template>

<script>
import ItemCategoriaNovo from "../produtos/ItemCategoriaNovo.vue";
import ItemCategoriaNovoCelular from "../produtos/ItemCategoriaNovoCelular.vue";

import { mapState } from "vuex";

export default {
  components: {
    ItemCategoriaNovo,
    ItemCategoriaNovoCelular
  },
  data() {
    return {
      drawer: false,
      celular: false,
      buscador: "",
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("login", ["usuarioLogado"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("produtos", ["categoria"]),
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    mediaQuery() {
      return window.matchMedia("(max-width: 1279px)");
    },
    nomeBoasVindas() {
      return this.dadosCliente.nome.split(" ")[0];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    carrinhoQuantidade() {
      return this.$store.getters["carrinhos/quantidade"];
    },
    produtosLink() {
      return "/products";
    },
    carrinhosLink() {
      return "/cart";
    },
    minhaConta() {
      return "/products/myaccount";
    },
    buscaLink() {
      return "/products/search/" + this.buscador;
    },
    barcodeLink() {
      return "/barcode";
    },
    categorianumber() {
      return this.$store.getters["loja/categoriaSelecionada"];
    },
  },
  methods: {
    async carregarBusca() {
      await this.$store.dispatch("produtos/carregarBusca", {
        busca: this.buscador,
      });
      this.buscador = "";
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    handleResize() {
      if (this.mediaQuery.matches) {
        this.drawer = false;
        this.celular = true;
      } else {
        this.drawer = true;
        this.celular = false;
      }
    },
  },
  watch: {
    async categorianumber(newCategory, oldCategory) {
      if (newCategory != oldCategory) {
        this.$store.dispatch("produtos/isProdutoLoading");

        await this.$store.dispatch("loja/resetPaginacao");
        await this.$store.dispatch("produtos/resetProdutos");
        await this.$store.dispatch("produtos/carregarCategoriaSelecionada", {
          ordem: "LAN%C3%87AMENTO",
        });

        this.$store.dispatch("produtos/isProdutoReady");
      }
    },
  },
  created() {
    this.carregarCategorias();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #13433a;
}

.search_bar {
  width: 400px;
}

@media (max-width: 768px) {
  .search_bar {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

form > a {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  justify-self: center;
  align-items: center;
  font-size: 40px;
  justify-content: space-around;
}

li {
  font-size: 15px;
  list-style: none;
  margin: 0 1rem;
}

#meuspedidos {
  color: #13433a;
}

#meuspedidos:active,
#meuspedidos:hover,
#meuspedidos.router-link-active {
  color: var(--primary-color);
}

a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-bottom: 2px solid transparent;
  padding-bottom: 0.25rem;
  width: max-content;
}

a:hover,
a:active,
a.router-link-active {
  color: #32424a;
  border-color: white;
}

button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 30px;
}

button:hover,
button:active {
  background-color: var(--primary-color);
  color: white;
}

p {
  color: black;
  margin: 0;
}
</style>
