import { createStore } from 'vuex';

import createPersistedState from "vuex-persistedstate";

import produtosModule from './modules/produtos/index.js';
import carrinhoModule from './modules/carrinhos/index.js';
import requestsModule from './modules/requests/index.js';
import formModule from './modules/form/index.js';
import lojaModule from './modules/loja/index.js';
import empresaModule from './modules/empresa/index.js';
import loginModule from './modules/login/index.js';

const dataState = createPersistedState({
    paths: ['carrinhos','login']
  })  

const store = createStore({
    modules: {
        produtos: produtosModule,
        carrinhos: carrinhoModule,
        requests: requestsModule,
        form: formModule,
        loja: lojaModule,
        login: loginModule,
        empresa: empresaModule
    },
    plugins: [dataState]
})



export default store;