<template>
  <div class="container">
    <h1>My orders</h1>
    <div class="filtroStyle">
      <h3 class="tituloFiltro">Filter by:</h3>
      <div class="row mt-5 mb-5">
        <div class="col-md">
          <div class="row">
            <label class="col-4" for="dataInicio">Start Date: </label>
            <input
              id="dataInicio"
              class="col dataStyle"
              type="date"
              v-model="dataInicio"
            />
          </div>
        </div>

        <div class="col-md">
          <div class="row">
            <label class="col-4" for="dataFim">End Date: </label>
            <input
              id="dataFim"
              class="col dataStyle"
              type="date"
              v-model="dataFim"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-end buttonStyle">
          <button
            class="btn btn-success botaoStyle"
            @click="handleLoadDataClick"
          >
            Search
          </button>
        </div>
      </div>
    </div>

    <hr />
    <div class="container">
      <div v-if="dadosPedidos != null && !carregamentoPedidos">
        <item-pedido
          v-for="pedido in dadosPedidos"
          :key="pedido.codigoVenda"
          :codigoVenda="pedido.codigoVenda"
          :dataFinalizacao="pedido.dataFinalizacao"
          :dataInclusao="pedido.dataInclusao"
          :descricao="pedido.descricao"
          :empresa="pedido.empresa"
          :itens="pedido.itens"
          :origem="pedido.origem"
          :notafiscal="pedido.notafiscal"
          :ChaveEletronicaNFeSaida="pedido.ChaveEletronicaNFeSaida"
          :URL_NFe="pedido.URL_NFe"
          :Rastreamento="pedido.Rastreamento"
          :URL_Rastreamento="pedido.URL_Rastreamento"
          :valorTotal="pedido.valorTotal"
          :previsao="pedido.previsao"
        ></item-pedido>
      </div>
      <div v-if="carregamentoPedidos" class="fullbody">
        <base-spinner></base-spinner><br />
      </div>
      <div v-if="dadosPedidos == null && !carregamentoPedidos" class="fullbody">
        <h3>The user has no orders yet.</h3>
        <button @click="$router.go(-1)">Go back</button>
      </div>
    </div>
  </div>

  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

import ItemPedido from "../components/pedidos/ItemPedido.vue";

export default {
  components: {
    ItemPedido,
  },
  data() {
    return {
      dataInicio: moment().startOf("month").format("YYYY-MM-DD"),
      dataFim: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("login", ["dadosPedidos"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("login", ["carregamentoPedidos"]),
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    async carregarMeusPedidos() {
      await this.$store.dispatch("login/carregarMeusPedidos", {
        cnpj: this.empresarodape.cnpj,
        email: this.dadosCliente.email,
        senha: this.dadosCliente.senha,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
      });
    },
    async handleLoadDataClick() {
      await this.carregarMeusPedidos();
    },
  },
  created() {
    this.carregarMeusPedidos();
    this.carregarRodape();
    this.carregarCategorias();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

h1 {
  color: var(--primary-color);
  margin: 25px 40px 25px 10px;
}

.botaoStyle {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: 1rem 1rem;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

.filtroStyle {
  margin: 25px 40px 25px 10px;
}

.dataStyle {
  border: 1px solid rgba(0, 0, 0, 0.36);
  border-radius: 5px;
  padding: 5px;
  margin: 0px 20px;
}

.fullbody {
  margin: auto;
  text-align: center;
  min-height: 100vh;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
  margin-top: 100px;
}

.form-control,
.button-control {
  display: inline;
  padding: 0px;
  margin: auto;
  max-width: 50rem;
  border-radius: 10px;
  border: 0px;
}

button {
  font: inherit;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  margin: 0px 20px 0px 0px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

.login {
  border: 1px solid black;
  padding: 20px;
  border-radius: 10px;
}

h2 {
  text-align: center;
  background: var(--primary-color);
  color: white;
  padding: 10px;
  border: 1px solid black;
  margin: -21px -21px 0px -21px;
  border-radius: 10px 10px 0px 0px;
}

form {
  text-align: left;
  padding: 1rem;
  margin: auto;
  max-width: 35rem;
}

label {
  font-weight: bold;
  display: block;
  margin: 1rem 0;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid select,
.invalid textarea {
  border: 1px solid red;
}
</style>
