export default {
    addRequest(state, payload) {
        state.requests.push(payload);
    },
    statusPedido(state, payload) {
        state.statusPedido = payload;
    },
    carregamentoIniciado(state) {
        state.carregamentoPedidos = true;
    },
    carregamentoConcluido(state) {
        state.carregamentoPedidos = false;
    }
}