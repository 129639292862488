<template>
  <div class="carousel-item col-md-6">
    <img
      :src="url_imagem"
      alt="imagem produto"
      class="d-block mx-auto"
      style="width: 30rem; height: auto"
    />
  </div>
</template>

<script>
export default {
  props: ["url_imagem"],
};
</script>

<style scoped></style>
