<template>
    <div>
      <router-link :to="categoriaLink" v-slot="{ href, route, navigate }">
        <v-btn color="black" variant="text" :href="href" @click="navigate()">
          {{ item }}{{ route.CategoriaPage }}
        </v-btn>
      </router-link>
  
      <span v-if="filhas.length > 0">
        <v-btn variant="plain" @click="ainda()">
          <v-icon right>mdi-arrow-down</v-icon>
        </v-btn>
      </span>
          
      <v-list v-if="showList" max-height="450">
        <v-list-item v-for="(item, index) in filhas" :key="index">
          <router-link :to="categoriaLinkFilha + item.codigo" v-slot="{ href, route, navigate }">
            <v-btn color="black" variant="plain" :href="href" @click="navigate()">
              {{ item.nome }}{{ route.CategoriaPage }}
            </v-btn>
          </router-link>
        </v-list-item>
      </v-list>
  
    </div>
  </template>
  
  <script>
  export default {
    props: ["item", "codigo", "filhas"],
    components: {},
    data() {
      return {
        showList: false
      };
    },
    computed: {
      loja() {
        return this.$store.getters["loja/lojaSelecionada"];
      },
      tipo() {
        return this.$store.getters["loja/tipoSelecionado"];
      },
      categoriaLink() {
        return (
          "/products/" +
          "categories/" +
          this.codigo
        );
      },
      categoriaLinkFilha() {
        return "/products/" + "categories/";
      },
    },
    methods: {
      ainda() {
        this.showList = !this.showList;
        console.log('ainda');
      }
    },
    created() {},
  };
  </script>
  
  <style scoped>
  </style>
  