<template>
  <swiper-container class="mySwiper" navigation="true">
    <swiper-slide
      ><img src="../../public/ban4.png" style="max-width: 1200px"
    /></swiper-slide>
    <!--     <swiper-slide><img src="../../public/ban2.jpg" alt=""></swiper-slide>
    <swiper-slide><img src="../../public/ban3.jpg" alt=""></swiper-slide> -->
  </swiper-container>

  <div class="d-flex justify-content-between">
    <h1>Product Catalog</h1>
    <div id="margin" class="row">
      <p id="ordem" class="col">Order by:</p>
      <select
        @change="onChange($event)"
        class="form-select d-inline col"
        id="orderby"
        style="width: fit-content"
        aria-label="select"
        v-model="ordenacao"
      >
        <option value="LAN%C3%87AMENTO" selected>New Arrivals</option>
        <option value="MENOR%20PRE%C3%87O">Lower Price</option>
        <option value="MAIOR%20PRE%C3%87O">Highest Price</option>
        <option value="MAIS%20VENDIDOS">Bestselling Products</option>
        <option value="MAIOR%20ESTOQUE">Most Stocked</option>
        <option value="MENOR%20ESTOQUE">Least Stocked</option>
      </select>
    </div>
  </div>
  <hr />
  <img
    v-if="topovisibility"
    @click="top"
    id="back-to-top"
    src="../../public/totop.png"
    alt="voltar ao topo botão"
  />
  <div>
    <div class="container">
      <ul style="padding: 0; min-height: 100vh">
        <div v-if="isCargando">
          <base-spinner></base-spinner>
        </div>
        <div class="row" v-if="!isCargando">
          <item-produto
            class="col-md col-6 p-1"
            v-for="produto in produtos"
            :key="produto.codigo"
            :nomeproduto="produto.nomeproduto"
            :url="produto.url"
            :detalhes="produto.detalhes"
            :preco="produto.preco"
            :saldo="produto.saldo"
            :codigo="produto.codigo"
            :referencia="produto.referencia"
          >
          </item-produto>
        </div>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
      </ul>
    </div>
  </div>
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

import ItemProduto from "../components/produtos/ItemProduto.vue";
import { mapState } from "vuex";
import router from "@/router";

export default {
  components: {
    ItemProduto,
  },
  data() {
    return {
      search: "",
      ordenacao: "LAN%C3%87AMENTO",
      isLoading: false,
      isCargando: false,
      topovisibility: false,
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("produtos", ["produtos"]),
    ...mapState("produtos", ["categoria"]),
    ...mapState("empresa", ["permissaoUsuario"]),
    ...mapState("login", ["dadosCliente"]),
    ...mapState("login", ["usuarioLogado"]),

    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    permissaoUsuarioPage() {
      if (this.permissaoUsuario == "2") {
        return true;
      } else if (this.permissaoUsuario == "1" && this.usuarioLogado == true) {
        if (this.dadosCliente.aprovado == "S") {
          return true;
        }
        return false;
      } else return false;
    },
  },
  methods: {
    async carregarProdutos() {
      if (this.produtos.length === 0 || this.produtos.length === 4) {
        this.isLoading = true;
        await this.$store.dispatch("produtos/carregarProdutos", {
          ordem: this.ordenacao,
        });
        this.isLoading = false;
      }
    },
    async onChange(event) {
      this.ordenacao = event.target.value;
      this.isCargando = true;

      await this.$store.dispatch("loja/resetPaginacao");
      await this.$store.dispatch("produtos/resetProdutos");
      await this.$store.dispatch("produtos/carregarProdutos", {
        ordem: this.ordenacao,
      });

      this.$nextTick(() => {
        this.isCargando = false;
      });
    },
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    async resertCarousel() {
      await this.$store.dispatch("produtos/resertCarousel");
    },
    handleIntersect(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.handleScroll();
        }
      });
    },
    async handleScroll() {
      if (router.currentRoute.value.fullPath == "/" + "products") {
        if (
          this.isLoading == false &&
          this.isCargando == false &&
          this.$store.getters["produtos/acabouprodutos"] != "acabou"
        ) {
          this.$store.dispatch("loja/paginacao");
          this.isLoading = true;
          this.topovisibility = true;
          await this.$store.dispatch("produtos/carregarProdutos", {
            ordem: this.ordenacao,
          });
          this.isLoading = false;
        }
      }
    },
    top() {
      window.scrollTo(0, 0);
    },
    carregarPermissaoUsuario() {
      this.$store.dispatch("empresa/permissaoUsuario");
    },
  },
  created() {
    this.carregarRodape();
    this.carregarProdutos();
    this.carregarCategorias();
    this.resertCarousel();
    this.carregarPermissaoUsuario();
  },
  mounted() {
    const mySwiper = new Swiper(".swiper-container", {
      direction: "horizontal",
      loop: true,
      slidesPerView: "auto",
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });
    mySwiper.on("slideChange", () => {});
    if (router.currentRoute.value.fullPath == "/" + "products") {
      window.addEventListener("scroll", () => {
        let options = {
          root: null,
          rootMargins: "0px",
          threshold: 0.5,
        };
        const observer = new IntersectionObserver(
          this.handleIntersect,
          options
        );
        observer.observe(window.document.querySelector("#footer"));
      });
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 1145) {
          this.topovisibility = true;
        } else {
          this.topovisibility = false;
        }
      });
    }
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

#orderby:focus {
  border-color: var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #13433a;
}

#back-to-top {
  position: fixed;
  width: 80px;
  bottom: 0%;
  right: 10px;
  cursor: pointer;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.fullbody {
  min-height: 100vh;
}

ul {
  list-style: none;
  margin: 2rem auto;
}

div {
  margin: 0;
  padding: 0;
  margin: auto;
  max-width: 85rem;
}

img {
  margin-left: 1rem;
}

input {
  margin: 0;
  margin: auto;
  width: 90%;
}

h1 {
  color: var(--primary-color);
  margin: 40px;
}

swiper-container {
  width: 100%;
  height: 100%;
}

swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

swiper-slide img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
