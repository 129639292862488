export default {
    produtos(state) {
        return state.itens;
    },
    disponibilidade(state) {
        return state.disponibilidade;
    },
    somaTotal(state) {
        return state.total;
    },
    quantidade(state) {
        return state.qtd;
    },
    selecionados(state) {
        return state.userSelect;
      },
}