import mutations from './mutations.js';
import getters from './getters.js';
import actions from './actions.js';

export default {
    namespaced: true,
    state() {
        return {
            produtos: [],
            produtoSimilar: [],
            produtoDado: [],
            categoriaDada: [],
            termoBuscado: [],
            carregamento: false,
            isCarregado: true,
            categoria: [],
            userSelect: [],
            carrosselUrl: null,
            carrosselUrlHeader: null,
            acabouprodutos: ''
        } 
    },
    mutations,
    getters,
    actions,
};