import { createRouter, createWebHistory } from "vue-router";

import AreaLogin from "./pages/AreaLogin.vue";
import ListaProdutos from "./pages/ListaProdutos.vue";
import UserCarrinho from "./pages/UserCarrinho.vue";
import BarCode from "./pages/BarCode.vue";
import ClienteForm from "./pages/ClienteForm.vue";
import ProdutoPage from "./pages/ProdutoPage.vue";
import CategoriaPage from "./pages/CategoriaPage.vue";
import BuscaPage from "./pages/BuscaPage.vue";
import MeusPedidos from "./pages/MeusPedidos.vue";
import MinhaConta from "./pages/MinhaConta.vue";
import ResumoPedido from "./pages/ResumoPedido.vue";

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    { path: "/login", component: AreaLogin, props: true },
    {
      path: "/cart",
      component: UserCarrinho,
      props: true,
    },
    { path: "/barcode", component: BarCode, props: true },
    {
      path: "/register",
      component: ClienteForm,
      props: true,
    },
    {
      path: "/products",
      component: ListaProdutos,
      props: true,
    },
    {
      path: "/products/myorders",
      component: MeusPedidos,
      props: true,
    },
    {
      path: "/products/myaccount",
      component: MinhaConta,
      props: true,
    },
    {
      path: "/checkout",
      component: ResumoPedido,
      props: true,
    },
    {
      path: "/products/:tokenProduto",
      component: ProdutoPage,
      props: true,
    },
    {
      path: "/products/categories/:tokenCategoria",
      component: CategoriaPage,
      props: true,
    },
    {
      path: "/products/search/:tokenBusca",
      component: BuscaPage,
      props: true,
    },
  ],
});

export default router;
