<template>
  <div class="container">
    <h1>My Account</h1>
  </div>
  <hr />
  <form
    @submit.prevent="submitForm"
    v-if="clienteDados == null || clienteDados == ''"
  >
    <div class="login">
      <h2>Login</h2>
      <div class="mb-3">
        <div class="form-control" :class="{ invalid: !email.isValid }">
          <label for="cadastroPessoaJuridica">Email:</label>
          <input
            type="email"
            id="email"
            v-model.trim="email.val"
            style="border: 1px solid black"
            @blur="clearValidity('email')"
          />
          <p v-if="!email.isValid">Invalid email.</p>
        </div>
        <div class="mb-3 col-md-6" :class="{ invalid: !senha.isValid }">
          <label for="senha" class="form-label">Password:</label>
          <input
            type="password"
            id="senha"
            v-model.trim="senha.val"
            style="border: 1px solid black"
            @blur="clearValidity('senha')"
          />
        </div>
        <p v-if="!senha.isValid">Invalid password.</p>
        <div class="form-text">
          Don't have an account yet? Please press register!
        </div>
      </div>
      <div v-if="isLoading"><base-spinner></base-spinner><br /></div>
      <div v-if="isEntered">
        <div class="button-control">
          <button class="active" @click="validateForm">Sign in</button>
        </div>

        <router-link :to="cadastroLink" v-slot="{ href, route, navigate }">
          <button
            class="active"
            :href="href"
            @click="
              resetCliente();
              navigate();
            "
          >
            Register{{ route.ClienteForm }}
          </button></router-link
        >
      </div>
    </div>
  </form>
  <div v-else id="opcoesConta">
    <ul>
      <li>
        <router-link :to="meusPedidosLink" v-slot="{ href, route, navigate }">
          <button :href="href" @click="navigate()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-bag-check-fill"
              viewBox="0 0 18 18"
            >
              <path
                fill-rule="evenodd"
                d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
              />
            </svg>
            My orders{{ route.MeusPedidos }}
          </button></router-link
        >
      </li>
      <li>
        <router-link :to="carrinhosLink" v-slot="{ href, route, navigate }">
          <button :href="href" @click="navigate()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-cart-check"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
              />
              <path
                d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
              />
            </svg>
            My Cart {{ route.UserCarrinho }}
            <p v-if="carrinhoQuantidade > 0">
              <strong
                >You have {{ carrinhoQuantidade }} items in your cart!*</strong
              >
            </p>
          </button>
        </router-link>
      </li>
      <li>
        <router-link :to="cadastroLink" v-slot="{ href, route, navigate }">
          <button :href="href" @click="navigate">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 18 18"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
            Update profile information {{ route.ClienteForm }}
          </button></router-link
        >
      </li>
      <li>
        <router-link :to="produtosLink" v-slot="{ href, route, navigate }">
          <button :href="href" @click="navigate">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="currentColor"
              class="bi bi-shop"
              viewBox="0 0 18 18"
            >
              <path
                d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"
              />
            </svg>
            Go to the products page {{ route.ListaProdutos }}
          </button>
        </router-link>
      </li>
      <li>
        <button class="edit" @click="resetCliente">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-box-arrow-left"
            viewBox="0 0 18 18"
          >
            <path
              fill-rule="evenodd"
              d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
            />
            <path
              fill-rule="evenodd"
              d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
            />
          </svg>
          Logout
        </button>
      </li>
    </ul>
  </div>
  <div id="espaco"></div>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="block">
            <p style="margin-top: 10px">Phone: +5522998679020</p>
            <p>Email: eric@sensualle.com.br</p>
            <br />
            <p>
              Developed by
              <a
                style="color: white"
                target="_blank"
                href="http://ciriuserp.com.br/"
                >Cirius ERP<img
                  style="width: 40px; margin-left: 10px"
                  src="../../public/logo.png"
                  alt="logo empresa"
              /></a>
            </p>
            <p style="margin-top: 10px">All rights reserved © 2022</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: false,
      isEntered: true,
      senha: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
    };
  },
  computed: {
    ...mapState("empresa", ["empresarodape"]),
    ...mapState("login", ["dadosPedidos"]),
    ...mapState("login", ["usuarioLogado"]),
    clienteDados() {
      return this.$store.getters["login/dadosCliente"];
    },
    carrinhoQuantidade() {
      return this.$store.getters["carrinhos/quantidade"];
    },
    loja() {
      return this.$store.getters["loja/lojaSelecionada"];
    },
    tipo() {
      return this.$store.getters["loja/tipoSelecionado"];
    },
    meusPedidosLink() {
      return "/products/myorders";
    },
    carrinhosLink() {
      return "/cart";
    },
    cadastroLink() {
      return "/register";
    },
    produtosLink() {
      return "/products";
    },
  },
  methods: {
    async carregarRodape() {
      await this.$store.dispatch("empresa/carregarRodape");
      document.title = this.empresarodape.nome;
    },
    async carregarCategorias() {
      await this.$store.dispatch("produtos/carregarCategorias");
    },
    validateForm() {
      this.formIsValid = true;
      if (
        this.senha.val.length < 4 ||
        this.senha.val.length > 20 ||
        this.senha.val == ""
      ) {
        this.senha.isValid = false;
        this.formIsValid = false;
      }
      if (this.email.val == "" || !this.email.val.includes("@")) {
        this.email.isValid = false;
        this.formIsValid = false;
        this.top();
      }
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    resetCliente() {
      this.$store.dispatch("login/resetCliente");
    },
    async submitForm() {
      if (this.formIsValid == true) {
        this.isEntered = false;
        this.isLoading = true;
        await this.$store.dispatch("login/carregarCliente", {
          email: this.email.val,
          senha: this.senha.val,
        });
        if (this.usuarioLogado) {
          await this.$store.dispatch("login/carregarMeusPedidos", {
            cnpj: this.empresarodape.cnpj,
            senha: this.senha.val,
            email: this.email.val,
            dataInicio: moment().startOf("month").format("YYYY-MM-DD"),
            dataFim: moment().format("YYYY-MM-DD"),
          });
        }
        this.isEntered = true;
        this.isLoading = false;
      }
    },
  },
  created() {
    this.carregarRodape();
    this.carregarCategorias();
  },
};
</script>

<style scoped>
@import "@/styles/variables.css";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

h1 {
  color: var(--primary-color);
  margin: 25px 40px 25px 10px;
}

footer {
  background-color: #232f3f;
  color: white;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.form-control,
.button-control {
  display: inline;
  padding: 0px;
  margin: auto;
  max-width: 50rem;
  border-radius: 10px;
  border: 0px;
}

.active {
  font: inherit;
  cursor: pointer;
  color: white;
  background-color: var(--primary-color);
  border: none;
  margin: 0px 20px 0px 0px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

button {
  font: inherit;
  cursor: pointer;
  color: black;
  background-color: transparent;
  border: none;
  margin: 0px 20px 0px 0px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

button:hover {
  font: inherit;
  cursor: pointer;
  color: white;
  background-color: var(--primary-color);
  border: none;
  margin: 0px 20px 0px 0px;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;
}

.edit {
  background-color: transparent;
  border: none;
}

.edit:hover {
  background-color: red;
  color: white;
  border: 1px solid red;
}

.login {
  border: 1px solid black;
  padding: 20px;
  border-radius: 10px;
}

@media (max-width: 758px) {
  .login {
    width: 350px;
  }
}

h2 {
  text-align: center;
  background: var(--primary-color);
  color: white;
  padding: 10px;
  border: 1px solid black;
  margin: -21px -21px 0px -21px;
  border-radius: 10px 10px 0px 0px;
}

form {
  text-align: left;
  padding: 1rem;
  margin: auto;
  width: 35rem;
}

label {
  font-weight: bold;
  display: block;
  margin: 1rem 0;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid select,
.invalid textarea {
  border: 1px solid red;
}

#espaco {
  height: 300px;
}

#opcoesConta {
  width: 350px;
  margin: auto;
  font-size: 20px;
}

ul {
  list-style: none;
  width: 50rem;
}

li {
  margin: 20px;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: var(--primary-color);
}
</style>
