export default {
    cepDados(state) {
        return state.cepDados;
    },
    codigoCliente(state) {
        return state.codigoCliente;
    },
    codigoEntrega(state) {
        return state.codigoEntrega;
    },
    status(state) {
        return state.status;
    }

}