export default {
    addCadastro(state, payload) {
        state.codigoCliente = payload;
    },
    addEntrega(state, payload) {
        state.codigoEntrega = payload;
    },
    addStatus(state, payload) {
        state.status = payload;
    },
    getCep(state, payload) {
        state.cepDados = payload;
    }
}