<template>
  <div class="containter">
    <ul class="pedido_data">
      <div class="row">
        <li>
          <h2><strong>Order Code:</strong> {{ codigoVenda }}</h2>
        </li>
      </div>
      <br />
      <div class="row">
        <div class="col-sm-5">
          <li><strong>Date of Submission:</strong> {{ dataInclusao }}</li>
        </div>
        <div class="col-sm">
          <li><strong>Estimated Delivery Date:</strong> {{ previsao }}</li>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5">
          <li><strong>Status:</strong> {{ descricao }}</li>
        </div>
        <div class="col-sm">
          <li><strong>Origin:</strong> {{ origem }}</li>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5">
          <li><strong>Company:</strong> {{ empresa }}</li>
        </div>
        <div class="col-sm">
          <li><strong>Total Order: </strong>${{ valorTotalPedido }} USD</li>
        </div>
      </div>
      <br v-if="notafiscal" />
      <div class="row">
        <div class="col-sm-5" v-if="notafiscal">
          <li><strong>Invoice:</strong> {{ notafiscal }}</li>
        </div>
        <div class="col-sm" v-if="ChaveEletronicaNFeSaida">
          <li>
            <strong>Electronic Key: </strong> {{ ChaveEletronicaNFeSaida }}
          </li>
        </div>
      </div>
      <div class="row">
        <div class="col-sm" v-if="URL_NFe">
          <li>
            <a :href="URL_NFe" target="_blank"
              ><strong>Click here to view the invoice.</strong></a
            >
          </li>
        </div>
      </div>
      <br v-if="Rastreamento" />
      <div class="row">
        <div class="col-sm-5" v-if="Rastreamento">
          <li><strong>Tracking:</strong> {{ Rastreamento }}</li>
        </div>
      </div>
      <div class="row">
        <div class="col-sm" v-if="URL_Rastreamento">
          <li>
            <a :href="URL_Rastreamento" target="_blank"
              ><strong>Click here to track the package</strong></a
            >
          </li>
        </div>
      </div>
      <meus-pedidos
        v-for="item in itens"
        :key="item.ref"
        :referencia="item.ref"
        :nomeproduto="item.nomeproduto"
        :descSku="item.descSku"
        :preco="item.preco"
        :quantidade="item.quantidade"
        :precoTotal="item.precoTotal"
        :url="item.url"
      ></meus-pedidos>
    </ul>
  </div>
</template>

<script>
import meusPedidos from "../selecionados/variacoes/meusPedidos.vue";

export default {
  components: {
    meusPedidos,
  },
  props: [
    "codigoVenda",
    "dataFinalizacao",
    "dataInclusao",
    "descricao",
    "empresa",
    "itens",
    "origem",
    "previsao",
    "valorTotal",
    "notafiscal",
    "ChaveEletronicaNFeSaida",
    "URL_NFe",
    "Rastreamento",
    "URL_Rastreamento",
  ],
  computed: {
    valorTotalPedido() {
      return this.valorTotal.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 20px;
  text-align: center;
  text-decoration: underline;
}

.pedido_data {
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem 2rem 0rem 2rem;
  margin: auto;
  max-width: 40rem;
  height: max-content;
  text-align: center;
}

ul {
  list-style: none;
  margin: 2rem auto;
}

li {
  font-size: 15px;
  text-align: left;
}
</style>
