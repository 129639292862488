import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";

export default {
  namespaced: true,
  state() {
    return {
        itens: [],
        disponibilidade: null,
        userSelect: [],
        total: 0,
        qtd: 0
    };
  },
  mutations,
  getters,
  actions,
};
