import router from '../../../router.js'

export default {
    tipoSelecionado() {
        return router.currentRoute.value.params.tokenTipo;
    },
    lojaSelecionada() {
        return router.currentRoute.value.params.tokenLoja;
    },
    produtoSelecionado() {
        return router.currentRoute.value.params.tokenProduto;
    },
    categoriaSelecionada() {
        return router.currentRoute.value.params.tokenCategoria;
    },
    buscaPesquisada() {
        return router.currentRoute.value.params.tokenBusca;
    },
    paginacao(state) {
        return state.pagina;
    }
}