export default {
  produtos(state) {
    return state.produtos;
  },
  produtoSimilar(state) {
    return state.produtoSimilar;
  },
  produtoDado(state) {
    return state.produtoDado;
  },
  categoriaDada(state) {
    return state.categoriaDada;
  },
  termoBuscado(state) {
    return state.termoBuscado;
  },
  carregamento(state) {
    return state.carregamento;
  },
  variacoes(state) {
    return state.coresTamanhos;
  },
  selecionados(state) {
    return state.userSelect;
  },
  acabouprodutos(state) {
    return state.acabouprodutos;
  },
  carrosselUrl(state) {
    return state.carrosselUrl;
  },
  carrosselUrlHeader(state) {
    return state.carrosselUrlHeader;
  },  
  categoria(state) {
    return state.categoria;
  },
  isCarregado(state) {
    return state.isCarregado;
  }

};
